import React, { useState } from 'react';

import Button from '@mui/material/Button';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import CreatePage from './CreatePage';

const CreateButton = ({ refetch }) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    refetch();
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddBoxOutlined />}
        onClick={handleOpenDialog}>
        Tạo đơn hàng
      </Button>
      <CreatePage open={open} onClose={onClose} />
    </>
  );
};

export default CreateButton;
