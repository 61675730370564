import React, { useState, useEffect } from 'react';
import { Button, Spin, Card, Typography, Row, Col, Space, Empty, Flex, Select } from 'antd';
import {
  apiGetAuctionProduct,
  apiGetAuctionProductBySeller
} from '@services/MangeCustomer/CustomerServices';
import { useParams } from 'react-router-dom';
import productItemImage from '@assets/images/customer/dashboard/product.png';
import { DownOutlined } from '@ant-design/icons';
import { ProductItem } from '@page/CustomerPage/Common/items/product';
import { optionPageSize, optionSortProduct } from '@contants/options';
import { PaginationCustom } from '@components/Pagination';

const List = ({ sellerId }) => {
  const [items, setItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState(20);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { categoryId } = useParams();
  const [sort, setSort] = useState('recommend');
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const fetchInitialItems = async () => {
    setLoading(true);
    try {
      const response = await apiGetAuctionProduct({
        page: currentPage - 1,
        size: pageSize,
        category: categoryId
      });
      if (response && response.data && response.data.data) {
        setItems(response.data.data);
        setTotalItems(response?.data?.page?.total_elements);
      }
    } catch (error) {
      console.error('Failed to fetch items:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialItemsBySellerId = async () => {
    setLoading(true);
    try {
      const response = await apiGetAuctionProductBySeller({
        page: currentPage - 1,
        size: pageSize,
        seller_id: sellerId
      });
      if (response && response.data && response.data.data) {
        setItems(response.data.data);
        setTotalItems(response?.data?.page?.total_elements);
      }
    } catch (error) {
      console.error('Failed to fetch items:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (sellerId) {
      fetchInitialItemsBySellerId();
    } else {
      fetchInitialItems();
    }
  }, [categoryId, pageSize, currentPage, sellerId]);

  const showMoreItems = async () => {
    setLoadingMore(true);
    try {
      const response = await apiGetAuctionProduct({
        page: Math.floor(items.length / 20),
        size: 20,
        category: categoryId
      });
      if (response && response.data && response.data.data) {
        setItems((prevItems) => [...prevItems, ...response.data.data]);
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
      }
    } catch (error) {
      console.error('Failed to fetch more items:', error);
    } finally {
      setLoadingMore(false);
    }
  };

  return (
    <div>
      <Flex justify="space-between">
        <Space>
          <Typography.Text>Sắp xếp theo</Typography.Text>
          <Select
            style={{ height: '36px' }}
            optionFilterProp="label"
            options={optionSortProduct}
            value={sort}
            onChange={(value) => setSort(value)}
          />
        </Space>
        <Space>
          <Typography.Text>Hiển thị trên mỗi trang</Typography.Text>
          <Select
            style={{ height: '36px' }}
            optionFilterProp="label"
            options={optionPageSize}
            value={pageSize}
            onChange={(value) => setPageSize(value)}
          />
        </Space>
      </Flex>
      <Typography></Typography>
      {items?.length ? (
        <>
          <Row gutter={[16, 16]} justify="start" style={{ marginTop: '16px', marginLeft: '0px' }}>
            {items.map((item, index) => (
              <Col key={index} xs={12} sm={12} md={8} lg={6}>
                <ProductItem
                  imgUrl={(item.images && item.images[0]) || productItemImage}
                  title={`${item.name ? item.name.slice(0, 25) : ''}...`}
                  startDate={item.start_date}
                  endDate={item.end_date}
                  priceYen={item.price}
                  priceDong={item.price ? Math.round(item.price) : 0}
                  auctionId={item.auction_id}
                />
              </Col>
            ))}
          </Row>
          {
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              {/* <Button
                style={{ backgroundColor: '#fff', color: '#626D7C' }}
                type="text"
                icon={<DownOutlined />}
                onClick={showMoreItems}
                loading={loadingMore}>
                Xem thêm
              </Button> */}
              <PaginationCustom
                current={currentPage}
                onChange={(page) => {
                  setCurrentPage(page);
                }}
                total={totalItems}
                pageSize={pageSize}
              />
            </div>
          }
        </>
      ) : (
        <Space
          direction="vertical"
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '36px'
          }}>
          <Empty description={'Không có dữ liệu!'} />
        </Space>
      )}
    </div>
  );
};

export default List;
