import React from 'react';
import { Button } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import notFoundImage from '@assets/images/user/Frame.png';
const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 633,
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#F3F4F6'
}));

const IMG = styled('img')(() => ({
  width: '100%',
  marginBottom: '32px',
  backgroundColor: '#F3F4F6'
}));
const NotFoundRoot = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh !important',
  backgroundColor: '#F3F4F6'
}));
const NotFoundPro = ({ data }) => {
  const navigate = useNavigate();
  const isSale = data;
  const subject = isSale ? 'Chưa có sản phẩm' : 'Trang không tồn tại';
  const description = isSale
    ? 'Vui lòng nhập link sản phẩm để tìm kiếm'
    : ' Trở về trang chủ và tiếp tục khám phá các sản phẩm khác.';

  return (
    <NotFoundRoot>
      <JustifyBox>
        <IMG src={notFoundImage} alt="" />
        <div style={{ margin: '24px 0' }}>
          <h2
            style={{
              color: 'var(--Text-colorcolor-Text-primaty, #192434)',
              textAlign: 'center',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '32px'
            }}>
            {subject}
          </h2>
          <span
            style={{
              color: ' var(--Text-colorcolor-Text-secondary, #626D7C)',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px' /* 142.857% */
            }}>
            {description}
          </span>
        </div>
        {isSale ? (
          ''
        ) : (
          <Button
            color="primary"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            style={{
              color: ' var(--Backgroud-Backgroud-1, #FFF)',
              textAlign: 'center',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '20px'
            }}
            onClick={() => navigate('/customer/dashboard')}>
            Về trang chủ
          </Button>
        )}
      </JustifyBox>
    </NotFoundRoot>
  );
};

export default NotFoundPro;
