import React from 'react';
import './index.css';
import { formatPrice } from '@contants/formatPrice';

export const ProductCard = ({ product }) => {
  return (
    <div className="record-item-custom">
      <div className="product-shopping">
        <div className="card-prod">
          <div className="thumbnail">
            <img
              alt="img"
              className="icon-brand"
              src="https://jancargo.com/static/svg/auction.svg"
            />
            <a className="prod-image">
              <img alt="img-product" src={product.images[0]} />
            </a>
            <label className="num-bids">
              <span className="bid-value">
                <img alt="bid" src="https://jancargo.com/static/favicons/judge.svg" />
                <span className="bold">{product.auction.number} Lượt đấu</span>
              </span>
            </label>
          </div>
          <div className="caption">
            <a className="group-prod-name">
              <span className="prod-name bold">{product.title.text}</span>
            </a>
            <div className="group-footer-info with-coundown">
              <div className="group-prod-price">
                <div className="bid-price">
                  <label className="price-vn fm-extra-bold">
                    <div className="price-jp" style={{ color: '#FF0000' }}>
                      ¥{formatPrice(product.price.value)}
                    </div>
                  </label>
                </div>
              </div>
              {product.price.buy_now !== '' && product.price.buy_now !== undefined && (
                <div className="group-prod-price">
                  <div className="bid-price">
                    <label className="price-vn fm-extra-bold">
                      <div className="price-jp">¥{formatPrice(product.price.buy_now)}</div>
                    </label>
                  </div>
                </div>
              )}
              <div className="group-action timedown">
                <label className="time-bids">
                  <span className="time-down">
                    <span className="bold" style={{ display: 'flex', alignItems: 'center' }}>
                      <img alt="time-down" src="https://jancargo.com/static/favicons/timer.svg" />
                      <span>{product.auction.finish_time}</span>
                    </span>
                  </span>
                </label>
                <img
                  className="heart"
                  alt="heart"
                  src="https://jancargo.com/static/icons/heart34.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
