import React, { useEffect, useState } from 'react';
import '../index.css';
import { Grid } from '@mui/material';
import { Button, Col, message, Row, Statistic, Typography } from 'antd';
import moment from 'moment';
import { bidProduct } from '@page/AdminPage/Auction/helpers/request';
import { SUCCESS } from '@utils/baseAPI/HttpStatusCode';
import { formatPrice } from '@contants/formatPrice';
import { contantAuthentication } from '@contants/index';
import { ReactComponent as Vector } from '@assets/images/user/vector.svg';
import AuctionPopup from '../Dialogs/DialogsBildAuction';
import { calculateTimeLeft } from '@contants/formatDayTime';
import DialogsHistoryAuction from '../Dialogs/DialogsHistoryAuction';
import { loadFromLocalStorage } from '@databases/localStorage';
import { constantExchangeRate } from '@contants/index';
const { Title, Text } = Typography;

export const ProductDetail = ({
  data,
  refetch,
  isOpenModalAuction,
  setIsOpenModalAuction,
  handleGetDataAuction,
  roleSale,
  customerId
}) => {
  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBid, setIsLoadingBid] = useState(false);
  const [error, setError] = useState('');
  const [price, setPrice] = useState(0);
  const [type, setType] = useState(1);
  const exchangeRateJPY = JSON.parse(
    loadFromLocalStorage(constantExchangeRate.EXCHANGE_RATE)
  )?.bank_sell;
  const [result, setResult] = useState(null);

  const hidePopup = () => setIsOpenModalAuction(false);
  const hidePopUpHistory = () => setIsOpenModalHistory(false);
  const showModalPopupHistory = () => setIsOpenModalHistory(true);
  const openModalAuction = (type) => {
    setType(type);
    if (type === 2) {
      setIsOpenModalAuction(true);
      return;
    }
    if (type === 1) {
      setIsOpenModalAuction(true);
      return;
    }
    if (type === 3) {
      setIsOpenModalAuction(true);
      return;
    }
    if (type === 4) {
      setIsOpenModalAuction(true);
      return;
    }
  };

  const roles = localStorage.getItem(contantAuthentication.USER_DATA)
    ? JSON.parse(localStorage.getItem(contantAuthentication.USER_DATA)).roles
    : null;

  const auction = () => {
    if (price > data?.price.value * 174) {
      setError('Giá đấu cần lớn hơn giá hiện tại.');
      return;
    }

    setIsLoading(true);
    bidProduct({
      auc_id: data?.detail.auction_id,
      buy_now: type === 2,
      price: price
    }).then((res) => {
      setIsLoading(false);
      if (res.status === SUCCESS) {
        message.success('Đấu giá thành công').then();
        setIsOpenModalAuction(false);
      }
    });
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(data?.detail.end_date));
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(data?.detail.end_date));
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, data?.detail.end_date]);
  const { days, hours, minutes, seconds } = timeLeft;
  useEffect(() => {
    if (data?.price) {
      if (data?.price?.is_bid_win !== undefined) {
        setResult(data);
      }
    }
  });
  useEffect(() => {
    if (result) {
      handleGetDataAuction(result);
    }
  }, [result]);

  const isBuyNow =
    data?.price.value !== '' &&
    data?.price.value !== undefined &&
    parseInt(data?.price.buy_now) != parseInt(data?.detail.start_price);
  return (
    <div className="product-body">
      <h1 className="product-name">{data?.title.text}</h1>
      <div className="auction-timer">
        <Row gutter={16} align="middle" justify="space-between">
          <Col span={6} push={18}>
            <div className="auction_right_top_title">
              <strong style={{ fontSize: '18px' }}>{data?.auction.number}</strong> Đang đấu giá
            </div>
            <div className="auction_right_bot_titel">
              <a
                rel="noreferrer"
                target="_blank"
                className="btn-link c-pointer"
                // href={`${location.search.slice(
                //   location.search.indexOf('dataSearch=') + 11,
                //   location.search.length
                // )}`}
                onClick={() => setIsOpenModalHistory(true)}>
                Lịch sử đấu giá
              </a>
              <Vector />
            </div>
          </Col>
          <Col span={18} pull={6}>
            <Row align="middle" justify="space-around" textAlign="center">
              <Col span={3}>
                <Title level={4}>
                  <Text strong>{!isBuyNow ? '' : days}</Text>
                </Title>
                <Title level={5} style={{ marginBottom: 0 }}>
                  <Text>Ngày</Text>
                </Title>
              </Col>
              <Col>:</Col>
              <Col span={3}>
                <Title level={4}>
                  <Text strong>{!isBuyNow ? '' : hours}</Text>
                </Title>
                <Title level={5} style={{ marginBottom: 0 }}>
                  <Text>Giờ</Text>
                </Title>
              </Col>
              <Col>:</Col>
              <Col span={3}>
                <Title level={4}>
                  <Text strong>{!isBuyNow ? '' : minutes}</Text>
                </Title>
                <Title level={5} style={{ marginBottom: 0 }}>
                  <Text>Phút</Text>
                </Title>
              </Col>
              <Col>:</Col>
              <Col span={3}>
                <Title level={4}>
                  <Text strong>{!isBuyNow ? '' : seconds}</Text>
                </Title>
                <Title level={5} style={{ marginBottom: 0 }}>
                  <Text>Giây</Text>
                </Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="product-body_price">
        <Row gutter={[24, 24]}>
          <Col className="gutter-row" span={12}>
            {data?.price.value !== '' && data?.price.value !== undefined && (
              <>
                <div className="label-price bold">Giá đấu hiện tại:</div>
                <div className="amount-price price-jp bold">
                  <span style={{ color: '#192434', fontSize: '18px' }}>
                    {formatPrice(data?.price.value)} ¥
                  </span>{' '}
                  (
                  <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                    {data?.price.value > 0 ? (
                      <span> ≈ {formatPrice(data?.price?.value * exchangeRateJPY, 2)} đ</span>
                    ) : (
                      <span>0 đ</span>
                    )}
                  </span>
                  )
                </div>
              </>
            )}
          </Col>
          {data?.price?.tax > 0 && data?.price?.value !== undefined && (
            <Col className="gutter-row" span={12}>
              <div className="label-price bold">Giá hiện tại (bao gồm thuế):</div>
              <div className="amount-price price-jp bold">
                <span style={{ color: '#192434', fontSize: '18px' }}>
                  {formatPrice(data?.price?.tax)} ¥
                </span>{' '}
                (
                <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                  {data?.price.value > 0 ? (
                    <span> ≈ {formatPrice(data?.price?.tax * exchangeRateJPY, 2)} đ</span>
                  ) : (
                    <span>0 đ</span>
                  )}
                </span>
                )
              </div>
            </Col>
          )}
          <Col className="gutter-row" span={12}>
            {data?.price.buy_now !== '' &&
              data?.price.buy_now !== undefined &&
              data?.price.buy_now > 0 && (
                <>
                  <div className="label-price bold">Giá mua thẳng:</div>
                  <div className="amount-price price-jp bold" style={{ marginBottom: 15 }}>
                    <span style={{ color: '#192434', fontSize: '18px' }}>
                      {formatPrice(data?.price.buy_now)} ¥
                    </span>{' '}
                    (
                    <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                      {data?.price.buy_now > 0 ? (
                        <span> ≈ {formatPrice(data?.price.buy_now * exchangeRateJPY, 2)} đ</span>
                      ) : (
                        <span>0 đ</span>
                      )}
                    </span>
                    )
                  </div>
                </>
              )}
          </Col>
          {data?.price?.buy_now_tax > 0 && data?.price?.buy_now_tax !== undefined && (
            <Col className="gutter-row" span={12}>
              <div className="label-price bold">Giá mua thẳng (bao gồm thuế):</div>
              <div className="amount-price price-jp bold">
                <span style={{ color: '#192434', fontSize: '18px' }}>
                  {formatPrice(data?.price?.buy_now_tax)} ¥
                </span>{' '}
                (
                <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                  {data?.price.value > 0 ? (
                    <span> ≈ {formatPrice(data?.price?.buy_now_tax * exchangeRateJPY, 2)} đ</span>
                  ) : (
                    <span>0 đ</span>
                  )}
                </span>
                )
              </div>
            </Col>
          )}
          {data?.bidding_status === 1 && (
            <Col span={24}>
              <div
                style={{
                  backgroundColor: '#E8EBEF',
                  width: '100%',
                  height: '44px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: '16px',
                  color: '#F82859',
                  fontSize: '14px',
                  fontWeight: '500'
                }}>
                {`Bạn đã đặt giá ${formatPrice(
                  data?.price?.bid_price
                )} ¥, giá đấu của bạn đã bị vượt`}
              </div>
            </Col>
          )}
          {data?.bidding_status === 2 && (
            <Col span={24}>
              <div
                style={{
                  backgroundColor: 'var(--primary-basic-10, #00904A1A)',
                  width: '100%',
                  height: '44px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: '16px',
                  color: '#00904A',
                  fontSize: '14px',
                  fontWeight: '500'
                }}>
                {`Bạn đã đặt giá ${formatPrice(
                  data?.price?.bid_price
                )} ¥, giá đấu của bạn đang là cao nhất`}
              </div>
            </Col>
          )}
        </Row>
      </div>

      <div>
        <Grid container spacing={3}>
          {!roleSale && roles.indexOf('ADMIN') === -1 && roles.indexOf('ACCOUNTING') === -1 && (
            <>
              {data?.price.value !== '' &&
                data?.price.value !== undefined &&
                parseInt(data?.price.buy_now) != parseInt(data?.detail.start_price) && (
                  <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingRight: '8px' }}>
                    <Button
                      loading={isLoadingBid}
                      onClick={() => openModalAuction(4)}
                      title="Đặt giá đấu ngay"
                      className="bid-now"
                      type="primary">
                      Đấu giá
                    </Button>
                  </Grid>
                )}
              {/* {data?.price.value !== '' && data?.price.value !== undefined && (
                <Grid item lg={4} md={4} sm={4} xs={4} style={{ paddingLeft: '8px' }}>
                  <Button
                    loading={isLoadingBid}
                    onClick={() => openModalAuction(3)}
                    title="Đặt giá đấu ngay"
                    className="bid-last"
                    type="primary"
                    // icon={
                    //   <img
                    //     style={{ width: 26, marginRight: 10 }}
                    //     src="https://jancargo.com/static/svg/bid_white.svg"
                    //     alt=""
                    //   />
                    // }
                  >
                    Săn phút chót
                  </Button>
                </Grid>
              )} */}
              {data?.price.buy_now !== '' &&
                data?.price.buy_now !== undefined &&
                parseInt(data?.price.buy_now, 10) != 0 && (
                  <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingLeft: '16px' }}>
                    <Button
                      title="Mua thẳng"
                      className="bid-snipe"
                      onClick={() => openModalAuction(5)}
                      type="primary">
                      Mua thẳng
                    </Button>
                  </Grid>
                )}
            </>
          )}
          {roleSale && (
            <>
              {data?.price.value !== '' &&
                data?.price.value !== undefined &&
                parseInt(data?.price.buy_now) != parseInt(data?.detail.start_price) && (
                  <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingRight: '8px' }}>
                    <Button
                      loading={isLoadingBid}
                      onClick={() => openModalAuction(4)}
                      title="Đặt giá đấu ngay"
                      className="bid-now"
                      type="primary">
                      Đấu giá
                    </Button>
                  </Grid>
                )}
              {data?.price.buy_now !== '' &&
                data?.price.buy_now !== undefined &&
                parseInt(data?.price.buy_now, 10) != 0 && (
                  <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingLeft: '16px' }}>
                    <Button
                      title="Mua thẳng"
                      className="bid-snipe"
                      onClick={() => openModalAuction(5)}
                      type="primary">
                      Mua thẳng
                    </Button>
                  </Grid>
                )}
            </>
          )}
        </Grid>
      </div>
      {isOpenModalAuction && (
        <AuctionPopup
          visible={isOpenModalAuction}
          onCancel={hidePopup}
          type={type}
          remainingTime={timeLeft}
          data={data}
          refetch={refetch}
          handleGetDataAuction={handleGetDataAuction}
          customerId={customerId}
          roleSale={roleSale}
        />
      )}
      {isOpenModalHistory && (
        <DialogsHistoryAuction
          histories={[]}
          onCancel={hidePopUpHistory}
          visible={isOpenModalHistory}
          data={data}
          customerId={customerId}
          roleSale={roleSale}
        />
      )}
    </div>
  );
};
