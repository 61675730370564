import { constantCategory } from '@contants/index';
import { loadFromLocalStorage } from '@databases/localStorage';
import { Button, Card, Checkbox, Col, Empty, Input, Radio, Row, Space, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
const { Search } = Input;
const CheckboxGroup = Checkbox.Group;
export const Filter = ({ categories, sellerId }) => {
  const navigate = useNavigate();
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const [isTranslateSearch, setIsTranslateSearch] = useState(false);
  const [productConditions, setProductConditions] = useState('ALL');
  const [priceConditions, setPriceConditions] = useState('ALL');
  const [sellerConditions, setSellerConditions] = useState('ALL');
  const [fomPrice, setFromPrice] = useState({
    auction: '',
    byNow: ''
  });
  const [toPrice, setToPrice] = useState({
    auction: '',
    byNow: ''
  });

  const plainOptions = [
    'Sản phẩm mới đăng',
    'Miễn phí vận chuyển nội địa',
    'Giá mua ngay',
    'Hình ảnh xác nhận'
  ];
  const [checkedListOption, setCheckedListOption] = useState('');

  const handleSearchPrice = () => {
    console.log(`Searching from ${fomPrice?.auction} to ${toPrice?.auction}`);
  };

  const onChangeTranslateType = (e) => {
    setIsTranslateSearch(e.target.value);
  };
  const onChangeProductConditions = (e) => {
    setProductConditions(e.target.value);
  };

  const onChangePriceConditions = (e) => {
    setPriceConditions(e.target.value);
  };

  const onChangeSellerConditions = (e) => {
    setSellerConditions(e.target.value);
  };

  const onChangeOption = (list) => {
    setCheckedListOption(list);
  };

  const handleClearFilter = () => {};

  return (
    <div>
      <Row gutter={[16, 16]}>
        {sellerId ? (
          <Col span={24}>
            <Card>
              <Typography.Title style={{ fontSize: '16px', fontWeight: '600', marginTop: '0px' }}>
                Loại sản phẩm
              </Typography.Title>
              <Space direction="vertical" style={{ marginTop: '8px', width: '100%' }}>
                <Radio.Group onChange={onChangePriceConditions} value={priceConditions}>
                  <Space direction="vertical">
                    <Radio value={'ALL'} style={{ fontSize: '14px', fontWeight: '400' }}>
                      Tất cả
                    </Radio>
                    <Radio value={'BID'} style={{ fontSize: '14px', fontWeight: '400' }}>
                      Có giá thầu
                    </Radio>
                    <Radio value={'PURCHASE'} style={{ fontSize: '14px', fontWeight: '400' }}>
                      Có giá mua
                    </Radio>
                  </Space>
                </Radio.Group>
              </Space>
            </Card>
          </Col>
        ) : (
          <Col span={24}>
            <Card>
              <Typography.Title style={{ fontSize: '16px', fontWeight: '600', marginTop: '0px' }}>
                Danh mục sản phẩm
              </Typography.Title>
              <Row gutter={[8, 8]}>
                {categories?.length ? (
                  categories?.slice(0, 6)?.map((category) => (
                    <Col span={24} key={category.id}>
                      <Button
                        key={category.id}
                        type="text"
                        onClick={() => navigate(`/product/category/${category?.id}`)}>
                        {category?.displayed_name}
                      </Button>
                    </Col>
                  ))
                ) : (
                  <Space
                    direction="vertical"
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Không có dữ liệu!'} />
                  </Space>
                )}
              </Row>
            </Card>
          </Col>
        )}

        <Col span={24}>
          <Card>
            <Typography.Title style={{ fontSize: '16px', fontWeight: '600', marginTop: '0px' }}>
              Tìm kiếm theo từ khoá
            </Typography.Title>
            <Space direction="vertical" style={{ marginTop: '8px', width: '100%' }}>
              <Search
                placeholder="Tìm kiếm sản phẩm"
                onSearch={onSearch}
                style={{
                  width: '100%',
                  height: '36px',
                  color: '#DBDFE5',
                  fontSize: '14px',
                  fontWeight: '400'
                }}
              />
              <Radio.Group onChange={onChangeTranslateType} value={isTranslateSearch}>
                <Space direction="vertical">
                  <Radio value={false} style={{ fontSize: '14px', fontWeight: '400' }}>
                    Không dịch và tìm kiếm
                  </Radio>
                  <Radio value={true} style={{ fontSize: '14px', fontWeight: '400' }}>
                    Tự động dịch và tìm kiếm
                  </Radio>
                </Space>
              </Radio.Group>
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Typography.Title style={{ fontSize: '16px', fontWeight: '600', marginTop: '0px' }}>
              Tìm theo giá
            </Typography.Title>
            <Space direction="vertical">
              <Typography.Title style={{ fontSize: '14px', fontWeight: '500' }}>
                Giá đấu hiện tại
              </Typography.Title>
              <Space.Compact>
                <Input
                  placeholder="Từ giá"
                  value={fomPrice?.auction}
                  onChange={(e) => setFromPrice((state) => ({ ...state, auction: e.target.value }))}
                  suffix="¥"
                  style={{
                    width: '50%',
                    height: '36px',
                    color: '#DBDFE5',
                    fontSize: '14px',
                    fontWeight: '400'
                  }}
                />
                <Input
                  placeholder="Đến giá"
                  value={toPrice?.auction}
                  onChange={(e) => setToPrice((state) => ({ ...state, auction: e.target.value }))}
                  suffix="¥"
                  style={{
                    width: '50%',
                    height: '36px',
                    color: '#DBDFE5',
                    fontSize: '14px',
                    fontWeight: '400'
                  }}
                />
              </Space.Compact>
              <Typography.Title style={{ fontSize: '14px', fontWeight: '500' }}>
                Giá mua ngay
              </Typography.Title>
              <Space.Compact>
                <Input
                  placeholder="Từ giá"
                  value={fomPrice?.byNow}
                  onChange={(e) => setFromPrice((state) => ({ ...state, byNow: e.target.value }))}
                  suffix="¥"
                  style={{
                    width: '50%',
                    height: '36px',
                    color: '#DBDFE5',
                    fontSize: '14px',
                    fontWeight: '400'
                  }}
                />
                <Input
                  placeholder="Đến giá"
                  value={toPrice?.byNow}
                  onChange={(e) => setToPrice((state) => ({ ...state, byNow: e.target.value }))}
                  suffix="¥"
                  style={{
                    width: '50%',
                    height: '36px',
                    color: '#DBDFE5',
                    fontSize: '14px',
                    fontWeight: '400'
                  }}
                />
              </Space.Compact>
              <Button
                onClick={handleSearchPrice}
                style={{
                  backgroundColor: '#2D9764',
                  borderColor: '#2D9764',
                  height: '36px',
                  width: '100%',
                  color: '#FFF',
                  marginTop: '8px'
                }}>
                Tìm theo giá
              </Button>
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Typography.Title style={{ fontSize: '16px', fontWeight: '600', marginTop: '0px' }}>
              Điều kiện sản phẩm
            </Typography.Title>
            <Space direction="vertical" style={{ marginTop: '8px', width: '100%' }}>
              <Radio.Group onChange={onChangeProductConditions} value={productConditions}>
                <Space direction="vertical">
                  <Radio value={'ALL'} style={{ fontSize: '14px', fontWeight: '400' }}>
                    Tất cả
                  </Radio>
                  <Radio value={'NEW'} style={{ fontSize: '14px', fontWeight: '400' }}>
                    Mới
                  </Radio>
                  <Radio value={'USED'} style={{ fontSize: '14px', fontWeight: '400' }}>
                    Đã sử dụng
                  </Radio>
                </Space>
              </Radio.Group>
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Typography.Title style={{ fontSize: '16px', fontWeight: '600', marginTop: '0px' }}>
              Người bán
            </Typography.Title>
            <Space direction="vertical" style={{ marginTop: '8px', width: '100%' }}>
              <Radio.Group onChange={onChangeSellerConditions} value={sellerConditions}>
                <Space direction="vertical">
                  <Radio value={'ALL'} style={{ fontSize: '14px', fontWeight: '400' }}>
                    Tất cả
                  </Radio>
                  <Radio value={'SHOP'} style={{ fontSize: '14px', fontWeight: '400' }}>
                    Cửa hàng
                  </Radio>
                  <Radio value={'PERSONAL'} style={{ fontSize: '14px', fontWeight: '400' }}>
                    Cá nhân
                  </Radio>
                </Space>
              </Radio.Group>
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Typography.Title style={{ fontSize: '16px', fontWeight: '600', marginTop: '0px' }}>
              Tuỳ chọn
            </Typography.Title>
            <Space direction="vertical" style={{ marginTop: '8px', width: '100%' }}>
              <CheckboxGroup
                options={plainOptions}
                value={checkedListOption}
                onChange={onChangeOption}
              />
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Button
            onClick={handleClearFilter}
            style={{
              backgroundColor: '#2D9764',
              borderColor: '#2D9764',
              height: '36px',
              width: '100%',
              color: '#FFF',
              marginTop: '8px'
            }}>
            Xoá tất cả
          </Button>
        </Col>
      </Row>
    </div>
  );
};
