import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default ({ buttons }) => {
  return (
    <Box display="flex">
      {buttons &&
        buttons.map((b, index) => (
          <Box key={index} mr={1}>
            {b}
          </Box>
        ))}
    </Box>
  );
};
