import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Breadcrumb } from '@components/index';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import ContainerBody from '@components/ContainerBody';
import { ProductPreview } from '@page/AdminPage/Auction/components/ProductPreview';
import { ProductDetail } from '@page/AdminPage/Auction/components/ProductDetail';
import { SummaryRight } from '@page/AdminPage/Auction/components/SummaryRight';
import { ProductOption } from '@page/AdminPage/Auction/components/ProductOption';
import { getDetailProduct } from '@page/AdminPage/Auction/helpers/request';
import { ResponseStatusCode, SUCCESS } from '@utils/baseAPI/HttpStatusCode';
import { Button, Col, Row, Skeleton, Spin } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { ProductDescription } from '@page/AdminPage/Auction/components/ProductDescription';
import ProcductRelative from './components/ProductRelative';
import ProcductRecentlyViewed from './components/ProductRecently';
import { ReactComponent as Share } from '@assets/images/customer/share.svg';
import './index.css';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import NotFoundPro from '@page/Page404Product';
import { URI_CRAWL } from '@contants/options';
import AuctionPageBild from '@page/PageAuctionBild';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
const Auction = ({ uriCustomer, customerId }) => {
  const location = useLocation();
  const { params: paramsValue } = useParams();
  const [productDetail, setProductDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isOpenModalAuction, setIsOpenModalAuction] = useState(false);
  const [resultAuction, setResultAuction] = useState();
  const [roleSale, setRoleSale] = useState(null);

  const handleGetDataAuction = (data) => {
    setResultAuction(data);
  };

  const { data } = useQuery(['get-user-info'], () => rootApi.get('/users/me'));
  const userInfo = data?.data?.data;

  useEffect(() => {
    if (userInfo) {
      const roleUser = userInfo.roles.some((role) => role === 'SALE');
      setRoleSale(roleUser);
    }
  }, [userInfo]);

  useEffect(() => {
    const fetchDetails = () => {
      if (paramsValue) {
        const uri = `${URI_CRAWL}/${paramsValue}`;
        fetchProductDetail(uri);
      } else if (location.search && location.search.includes('dataSearch=')) {
        const params = location.search.split('dataSearch=')[1];
        fetchProductDetail(params);
      } else if (uriCustomer) {
        fetchProductDetail(uriCustomer);
      }
    };
    fetchDetails();
  }, [paramsValue, location, isOpenModalAuction, uriCustomer]);

  const fetchProductDetail = (source) => {
    if (source === '' || !source) return;
    setIsLoading(true);
    getDetailProduct({
      source
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === SUCCESS) {
          res.data.images = res.data.images.map((item) => {
            return {
              original: item,
              thumbnail: item
            };
          });
          setProductDetail(res.data);
          setIsNotFound(false);
        }
        if (res.status === ResponseStatusCode.NOT_FOUND) {
          setProductDetail(res.data);
          setIsNotFound(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <ContainerBody>
      <div
        className="product_auction_containers"
        style={{ backgroundColor: isNotFound ? '#f4f5f7' : '#fafafa' }}>
        {isLoading ? (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <Skeleton active />
          </div>
        ) : isNotFound ? (
          <>
            <NotFoundPro data={roleSale} />
          </>
        ) : resultAuction?.price?.is_bid_win !== undefined ? (
          <AuctionPageBild data={resultAuction} roleSale={roleSale} />
        ) : productDetail ? (
          <>
            {!roleSale ? (
              <div className="breadcrumb">
                <Breadcrumb
                  homePath={'/customer/dashboard'}
                  routeSegments={[{ name: 'Sản phẩm', path: '/account/auction' }]}
                />
              </div>
            ) : (
              ''
            )}
            {productDetail ? (
              <div
                className="product_auction_container "
                style={{ padding: uriCustomer ? '16px' : '24px' }}>
                <Row
                  gutter={[16, 16]}
                  justify="start"
                  style={{
                    marginTop: '16px',
                    marginLeft: '0px',
                    paddingRight: '0',
                    borderBottom: ' 1px solid var(--Stoke-Stoke, #DBDFE5)'
                  }}>
                  <Col md={8} style={{ paddingRight: 0 }}>
                    <div className="section">
                      <ProductPreview data={productDetail?.images} />
                      <div className="left_bot_section">
                        <div className="left_bot_section_row">
                          <div className="left_bot_section_share">
                            <span style={{ display: 'block' }}>Chia sẻ</span>
                            <Button
                              type="text"
                              icon={<Share className="left_bot_section_share_icon" />}
                              key="share"
                              style={{ borderRadius: '36px' }}
                            />
                          </div>
                          <div className="left_bot_section_favorite">
                            <Button
                              type="text"
                              icon={
                                favorite ? (
                                  <HeartFilled style={{ color: '#626D7C', fontSize: '19px' }} />
                                ) : (
                                  <HeartOutlined style={{ color: '#626D7C', fontSize: '19px' }} />
                                )
                              }
                              key="favorite"
                              style={{ borderRadius: '36px' }}
                              onClick={() => setFavorite(!favorite)}
                            />
                            <span style={{ display: 'block' }}> Yêu thích</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} style={{ padding: '0 24px 24px' }}>
                    <ProductDetail
                      data={productDetail}
                      refetch={fetchProductDetail}
                      isOpenModalAuction={isOpenModalAuction}
                      setIsOpenModalAuction={setIsOpenModalAuction}
                      handleGetDataAuction={handleGetDataAuction}
                      roleSale={roleSale}
                      customerId={customerId}
                    />
                  </Col>
                  <Col md={4}>
                    <SummaryRight
                      data={productDetail}
                      yahoo_id={productDetail?.detail?.auction_id}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <div style={{ position: 'relative', width: '100%' }}>
                {isLoading && (
                  <Spin
                    style={{ position: 'absolute', top: 10, left: '50%' }}
                    spinning={isLoading}
                  />
                )}
                {!isLoading && (
                  <div style={{ margin: 'auto', marginTop: 20, textAlign: 'center' }}>
                    Vui lòng tìm kiếm sản phẩm để đấu giá
                  </div>
                )}
              </div>
            )}
            {productDetail && (
              <>
                <div>
                  <ProductOption data={productDetail} />
                </div>
              </>
            )}
            {!roleSale && (
              <>
                <ProcductRelative />
              </>
            )}
            {productDetail && (
              <>
                <div style={{ backgroundColor: '#0000' }}>
                  <ProductDescription data={productDetail} />
                </div>
              </>
            )}
            {!roleSale && <ProcductRecentlyViewed />}
          </>
        ) : (
          <>
            <NotFoundPro data={roleSale} />
          </>
        )}
      </div>
    </ContainerBody>
  );
};

export default compose(withPaginate)(Auction);
