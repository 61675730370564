import React, { useState } from 'react';

import { styled, useTheme } from '@mui/system';
import { Icon, IconButton } from '@mui/material';

const SearchContainer = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  padding: '6px 0'
}));

const SearchInput = styled('input')(({ theme }) => ({
  width: '100%',
  outline: 'none',
  border: 'none',
  fontSize: '15px',
  height: 'calc(100% - 5px)'
}));

const SearchInputBox = ({ onChange }) => {
  const [value, setValue] = useState('');
  const onClose = () => {
    setValue('');
  };

  const onChangeText = (event) => {
    setValue(event.target.value);
    onChange(event);
  };

  const { palette } = useTheme();
  const textColor = palette.text.primary;

  return (
    <SearchContainer>
      <IconButton>
        <Icon sx={{ color: textColor }}>search</Icon>
      </IconButton>
      <SearchInput value={value} onChange={onChangeText} placeholder="Tìm kiếm..." autoFocus />
      {value && (
        <IconButton onClick={onClose} sx={{ verticalAlign: 'middle' }}>
          <Icon sx={{ color: textColor }}>close</Icon>
        </IconButton>
      )}
    </SearchContainer>
  );
};

export default SearchInputBox;
