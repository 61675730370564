import React, { useState } from 'react';
import { Input, Select, DatePicker, Button } from 'antd';
import { FORMAT_DATE_ISO } from '@utils/utils';
import { FileOpenOutlined, SearchOutlined } from '@mui/icons-material';
import { exportAdminList } from '@page/AdminPage/AdminUserProduct/helpers/request';
import { contantAuthentication } from '@contants/index';

export const Filters = ({ onSearch, onClear }) => {
  const roles = localStorage.getItem(contantAuthentication.USER_DATA)
    ? JSON.parse(localStorage.getItem(contantAuthentication.USER_DATA)).roles
    : null;

  const statuses = [
    { text: 'Thắng', value: 'WON' },
    { text: 'Thua', value: 'LOST' },
    { text: 'Đang đấu giá', value: 'BIDDING' }
  ];

  const initParams = {
    username: null,
    auction_account_name: null,
    created_at_from: null,
    created_at_to: null,
    status: null
  };

  const [params, setParams] = useState(initParams);

  const changeParam = (field, value) => {
    setParams({ ...params, [field]: value });
  };

  const handleSearch = () => {
    onSearch({
      ...params,
      username: params.username !== '' ? params.username : null,
      auction_account_name: params.auction_account_name !== '' ? params.auction_account_name : null,
      created_at_from: params.created_at_from
        ? params.created_at_from.startOf('day').format(FORMAT_DATE_ISO)
        : null,
      created_at_to: params.created_at_to
        ? params.created_at_to.endOf('day').format(FORMAT_DATE_ISO)
        : null
    });
  };

  const handleExport = () => {
    exportAdminList(params).then((res) => {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', 'Danh_sach_san_pham_dau_gia.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  const clearSearch = () => {
    setParams(initParams);
    onClear();
  };

  return (
    <div>
      <div className="group-form">
        {(roles.indexOf('ADMIN') !== -1 || roles.indexOf('ACCOUNTING') !== -1) && (
          <>
            <Input
              value={params.username}
              onChange={(e) => changeParam('username', e.target.value)}
              rootClassName="form-input"
              placeholder="Nhập tên đăng nhập"
            />
            <Input
              value={params.username}
              onChange={(e) => changeParam('auction_account_name', e.target.value)}
              rootClassName="form-input"
              placeholder="Nhập tên tài khoản đấu giá"
            />
          </>
        )}
        <Select
          placeholder="Chọn trạng thái"
          className="form-input"
          value={params.status}
          onSelect={(e) => changeParam('status', e)}>
          {statuses.map((status, index) => {
            return (
              <Select.Option value={status.value} key={index}>
                {status.text}
              </Select.Option>
            );
          })}
        </Select>
        <DatePicker
          format="DD/MM/YYYY"
          value={params.created_at_from}
          rootClassName="form-input"
          placeholder="Chọn thời gian từ"
          onChange={(e) => changeParam('created_at_from', e)}
        />
        <DatePicker
          format="DD/MM/YYYY"
          value={params.created_at_to}
          rootClassName="form-input"
          placeholder="Chọn thời gian đến"
          onChange={(e) => changeParam('created_at_to', e)}
        />
        <Button
          icon={<SearchOutlined />}
          type="primary"
          style={{ marginRight: 15, display: 'flex' }}
          onClick={handleSearch}>
          Tìm kiếm
        </Button>
        {(roles.indexOf('ADMIN') !== -1 || roles.indexOf('ACCOUNTING') !== -1) && (
          <Button
            type="primary"
            style={{ marginRight: 15, display: 'flex' }}
            onClick={handleExport}
            icon={<FileOpenOutlined />}>
            Xuất excel
          </Button>
        )}
        <Button type="default" onClick={clearSearch}>
          Xóa lọc
        </Button>
      </div>
    </div>
  );
};
