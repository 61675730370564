import React from 'react';
import Breadcrumb from '@components/Breadcrumb';
import ContainerBody from '@components/ContainerBody';
import { Detail } from './Detail';
import './styles.scss';

const UserDetail = () => {
  return (
    <ContainerBody>
      <div>
        <Breadcrumb
          homePath={'/account/dashboard'}
          routeSegments={[
            { name: 'Trang chủ', path: '/account/dashboard' },
            { name: `Chi tiết người dùng` }
          ]}
        />
      </div>
      <Detail />
    </ContainerBody>
  );
};

export default UserDetail;
