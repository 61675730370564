import rootApi from '@utils/baseAPI/WrapperApi';

export const apiGetAllCurrencies = (data) => {
  return rootApi.get('/admin/currencies', { params: data });
};

export const apiUpdateCurrency = (id, data) => rootApi.put(`admin/currencies/${id}`, data);

export const apiDeleteCurrency = (id) => rootApi.delete(`admin/currencies/${id}`);

export const apiGetHistoryUpdateCurrency = (data) => {
  return rootApi.get('admin/currencies/history', { params: data });
};

export const apiGetDetailCurrency = (data) => {
  return rootApi.get('admin/currencies/detail', { params: data });
};
