import React from 'react';
import { Card, Grid, styled, useTheme, Box, CircularProgress } from '@mui/material';
import { Fragment } from 'react';
import StatCards from './shared/StatCards';
import Deposit from './shared/Deposit';
import TopSellingTable from './shared/TopTransactionLog';
import UpgradeCard from './shared/ActivateAuction';
import Wallet from './shared/Wallet';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const H4 = styled('h4')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginBottom: '16px',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary
}));

const Analytics = () => {
  const { palette } = useTheme();
  const { data, isLoading } = useQuery(['get-user-info'], () => rootApi.get('/users/me'));
  const roles = data?.data.data.roles || [];

  return (
    <Fragment>
      <ContentBox className="analytics">
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {roles.includes('ADMIN') || roles.includes('ACCOUNTING') ? (
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    component="img"
                    src="/assets/slider.png"
                    sx={{ width: '100%', height: 'auto', marginBottom: 2 }}
                  />
                  <StatCards />
                  <TopSellingTable />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <Box
                    component="img"
                    src="/assets/slider.png"
                    sx={{ width: '100%', height: 'auto', marginBottom: 2 }}
                  />
                  <StatCards />
                  <TopSellingTable />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <UpgradeCard />
                  <Wallet />
                  <Deposit />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </ContentBox>
    </Fragment>
  );
};

export default Analytics;
