import React from 'react';
import { Button, Card, styled } from '@mui/material';

const CardRoot = styled(Card)(({ theme }) => ({
  marginBottom: '24px',
  padding: '24px !important',
  background: theme.palette.primary.light,
  [theme.breakpoints.down('sm')]: { paddingLeft: '16px !important' }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  position: 'relative',
  padding: '24px !important',
  background: theme.palette.grey[300],
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const Paragraph = styled('p')(({ theme }) => ({
  margin: 0,
  paddingTop: '24px',
  paddingBottom: '24px'
}));

const UpgradeCard = () => {
  return (
    <CardRoot>
      <StyledCard elevation={0}>
        <img src="/assets/images/illustrations/upgrade.svg" alt="upgrade" />
        <Paragraph>
          Kích hoạt đấu giá<b> VIP</b>
        </Paragraph>
        <Button
          size="large"
          color="warning"
          variant="contained"
          sx={{ textTransform: 'uppercase' }}>
          KÍCH HOẠT
        </Button>
      </StyledCard>
    </CardRoot>
  );
};

export default UpgradeCard;
