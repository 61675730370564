import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import InputField from '@components/FormControls/InputField';
import SelectField from '@components/FormControls/SelectField';
import DatePickerField from '@components/FormControls/DatePickerField';
import RadioGroupField from '@components/FormControls/RadioGroupField';
import FormField from '@components/FormControls/FormField';

import { ASSET_TYPE } from '@contants/options';
import { formCreateOrderShipping } from './validate';
import { createOrderShoppingCart } from '../../helpers/requests';
import get from 'lodash/get';
import moment from 'moment';
import { dateFormat } from '@utils/constant';

function CreatePage({ open, onClose }) {
  const { mutateAsync, isLoading: isCreatingOrder, isSuccess } = createOrderShoppingCart();
  const formik = useFormik({
    initialValues: { year: ASSET_TYPE[0] },
    validationSchema: formCreateOrderShipping,
    onSubmit: (values) => {
      const data = {
        year: parseInt(get(values, 'year').value, 10)
      };
      mutateAsync(data);
    }
  });

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
        <DialogTitle>Thêm mới đơn vận chuyển</DialogTitle>
        <DialogContent>
          <FormField
            onCloseDialog={onClose}
            formik={formik}
            isLoading={isCreatingOrder}
            isSuccess={isSuccess}>
            <SelectField
              formik={formik}
              options={ASSET_TYPE}
              required
              name="asset_type"
              label="Loại hàng hóa"
            />
            <InputField formik={formik} name="order_code" required label="Mã vận chuyển" />
            <InputField formik={formik} name="order_number" required label="Số vận chuyển" />
          </FormField>
        </DialogContent>
      </Dialog>
    </div>
  );
}

CreatePage.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default CreatePage;
