import rootApi from '@utils/baseAPI/WrapperApi';
import { Button, Modal, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import './DialogsHistory.css'; // Import the CSS file
const columns = ({ refetch }) => {
  return [
    {
      title: 'Người đấu giá',
      dataIndex: 'auction_account_name',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {d?.username}
        </Typography>
      ),
      width: 220
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {d?.quantity}
        </Typography>
      ),
      width: 220
    },
    {
      title: 'Giá đặt',
      dataIndex: 'current_price',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {d?.current_price}
        </Typography>
      ),
      width: 220
    }
  ];
};
const DialogsHistoryAuction = ({
  histories,
  onCancel,
  visible,
  data: product,
  roleSale,
  customerId
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, refetch, error, isSuccess } = useQuery(
    ['user-get-history-auction', currentPage, pageSize],
    () =>
      rootApi.get(`/user-products`, {
        params: {
          page: currentPage - 1,
          size: pageSize,
          product_id: product?.id,
          ...(roleSale && { customer_id: +customerId })
        }
      })
  );
  return (
    <div>
      <Modal
        title="Lịch sử đấu giá sản phẩm"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button
            key="back"
            style={{
              height: '36px',
              backgroundColor: '#E8EBEF',
              color: '#192434',
              fontSize: '14px',
              fontWeight: '500',
              borderRadius: '4px'
            }}
            onClick={onCancel}>
            Đóng
          </Button>
        ]}
        width={720}
        style={{
          paddingLeft: '16px',
          paddingRight: '16px',
          cursor: 'pointer'
        }}>
        <Table
          bordered
          size="small"
          loading={isLoading}
          dataSource={data?.data?.data || []}
          columns={columns({ refetch })}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: data?.data?.totalItems || 0,
            showSizeChanger: true,
            // onChange: handleChangePage,
            // onShowSizeChange: handleChangePage,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
          style={{}}
          rowKey={(record) => record.id}
          className="custom-table" // Add a custom class for additional styling
        />
      </Modal>
    </div>
  );
};

export default DialogsHistoryAuction;
