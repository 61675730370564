import rootApi from '@utils/baseAPI/WrapperApi';

export const apiGetCategory = (data) => {
  return rootApi.get('/categories', { params: data });
};

export const apiGetPopularShop = (data) => {
  return rootApi.get('/sellers', { params: data });
};

export const apiGetAuctionProduct = (data) => {
  return rootApi.get('/products/get-by-category', { params: data });
};

export const apiGetRecentProduct = (data) => {
  return rootApi.get('/users/product/seen', { params: data });
};

export const apiGetAuctionProductBySeller = (data) => {
  return rootApi.get('/products/get-by-seller', { params: data });
};

export const apiAddFavoriteSeller = (data) => {
  return rootApi.post('/sellers/add-favourite', data);
};

export const apiRemoveFavoriteSeller = (data) => {
  return rootApi.post('/sellers/remove-favourite', data);
};

export const apiActiveVIP = (data) => {
  return rootApi.post('/users/upgrade-vip', data);
};

export const apiGetAuctionProductByUser = (data) => {
  return rootApi.get('/user-products', { params: data });
};

export const apiGetAllCustomer = (data) => {
  return rootApi.get('/admin/users', { params: data });
};

export const apiDeleteCustomer = (id) => {
  return rootApi.delete(`/admin/users/remove/${id}`);
};

export const apiAddCustomer = (data) => {
  return rootApi.post(`/admin/users/create-user`, data);
};
