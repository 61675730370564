import React, { useState } from 'react';
import { compose } from 'redux';
import { Breadcrumb } from '@components/index';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import ContainerBody from '@components/ContainerBody';
import { Categories } from '@page/AdminPage/Products/components/Categories';
import { MainContent } from '@page/AdminPage/Products/components/MainContent';
import { Pagination, Spin } from 'antd';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  return (
    <ContainerBody>
      <div className="breadcrumb">
        <Breadcrumb
          homePath={'/account/dashboard'}
          routeSegments={[
            { name: 'Mua hàng & đấu giá', path: '/account/bids' },
            { name: 'Sản phẩm đấu giá' }
          ]}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: 200 }}>
          <Categories
            page={page}
            setProducts={(value) => setProducts(value)}
            setLoading={(value) => setLoading(value)}
            setMaxPage={(value) => setMaxPage(value)}
          />
        </div>
        <div style={{ width: 'calc(100% - 220px)' }}>
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <Spin spinning={loading} />
          </div>
          {!loading && (
            <div>
              <div style={{ marginBottom: 20 }}>
                <Pagination
                  showSizeChanger={false}
                  current={page}
                  pageSize={50}
                  total={maxPage * 50}
                  onChange={(e) => setPage(e)}
                />
              </div>
              <MainContent products={products} />
              <div style={{ marginBottom: 20 }}>
                <Pagination
                  showSizeChanger={false}
                  current={1}
                  pageSize={50}
                  total={maxPage * 50}
                  onChange={(e) => setPage(e)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </ContainerBody>
  );
};

export default compose(withPaginate)(Products);
