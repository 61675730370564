import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Typography, Spin, Space, Empty } from 'antd';
import { products, categoriesx } from '../../helpers/product';
import { Items } from './item';
import { apiGetRecentProduct } from '@services/MangeCustomer/CustomerServices';
import productItemImage from '@assets/images/customer/dashboard/product.png';
import { ProductItem } from '@page/CustomerPage/Common/items/product';
import { Nodata } from '@utils/utils';

const ProcductRelative = ({ data }) => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetch = async () => {
    try {
      setIsLoading(true);
      const res = await apiGetRecentProduct({
        page: 0,
        size: 6
      });
      setCategories(res.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);
  return (
    <div>
      {isLoading ? (
        <Spin size="small" />
      ) : (
        <>
          <Card style={{ marginTop: '16px', borderRadius: '12px' }}>
            <Typography.Title style={{ fontSize: '20px', fontWeight: '600', marginTop: '0px' }}>
              Sản phẩm tương tự
            </Typography.Title>
            <Row
              className="grid-container"
              gutter={[16, 16]}
              justify="start"
              style={{ marginTop: '16px', marginLeft: '0px' }}>
              {categories?.length ? (
                categories.map((item, index) => (
                  <Col key={index} xs={12} sm={12} md={6} lg={4}>
                    <ProductItem
                      imgUrl={item?.product_response?.images?.[0] ?? productItemImage}
                      title={`${item?.product_response?.name?.slice(0, 30)}...`}
                      startDate={item?.product_response?.start_date}
                      endDate={item?.product_response?.end_date}
                      priceYen={item?.product_response?.price}
                      priceDong={Math.round(item?.product_response?.price, 2)}
                      auctionId={item?.product_response?.auction_id}
                    />
                  </Col>
                ))
              ) : (
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '36px'
                  }}>
                  <Empty description={'Không có dữ liệu!'} />
                </Space>
              )}
            </Row>
          </Card>
        </>
      )}
    </div>
  );
};

export default ProcductRelative;
