import React, { useState, useEffect } from 'react';
import { Card, Typography, Row, Col } from 'antd';
import List from './components/List';
import { Filter } from './components/Filter';
import { Breadcrumb } from '@components/index';
import { constantCategory } from '@contants/index';
import { loadFromLocalStorage } from '@databases/localStorage';
import { useParams } from 'react-router-dom';
import { Seller } from './components/Seller';
import { useQuery } from '@utils/utils';

const Product = () => {
  const { categoryId } = useParams();
  const { sellerId } = useParams();
  const query = useQuery();
  const username = query.get('username');
  const categories = JSON.parse(loadFromLocalStorage(constantCategory.CATEGORY) ?? []);
  return (
    <div className="analytics" style={{ margin: '30px' }}>
      <div className="breadcrumb">
        <Breadcrumb
          homePath={'/customer/dashboard'}
          routeSegments={
            sellerId
              ? [
                  {
                    name: 'Cửa hàng yêu thích',
                    path: '/'
                  },
                  {
                    name: username
                  }
                ]
              : [
                  {
                    name: categoryId
                      ? categories?.find((item) => item?.id === Number(categoryId))?.displayed_name
                      : 'Sản phẩm đấu giá'
                  }
                ]
          }
        />
      </div>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col lg={6} md={6} sm={12}>
          {sellerId && (
            <div style={{ marginBottom: '16px' }}>
              <Seller sellerId={sellerId} />
            </div>
          )}
          <Filter categories={categories} sellerId={sellerId} />
        </Col>
        <Col lg={18} md={18} sm={12}>
          <List sellerId={sellerId} />
        </Col>
      </Row>
    </div>
  );
};

export default Product;
