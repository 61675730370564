// React js
import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// components
import { ProtectedRoute } from '@components/ProtectedRoute';

// layouts
import DashboardLayout from '@layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from '@layouts/LogoOnlyLayout';

// Pages
import HomePage from '@page/HomePage';
import NotFound from '@page/Page404';
import DashboardApp from '@page/Dashboard/DashboardApp';
import ShoppingCart from '@page/CustomerPage/Cart/ShoppingCart';
import OrderShipping from '@page/CustomerPage/Orders/OrderShipping';
import AuctionPage from '@page/AdminPage/Auction';

// Router.
import materialRoutes from '@components/material-kit/MaterialRoutes';
import ChartsRoute from '@components/charts/ChartsRoute';
import authRoutes from '@page/Auth/AuthRoutes';
import customerRoutes from '@page/CustomerPage/CustomerRouters';
import adminRouters from '@page/AdminPage/AdminRoutes';
import accountingRouters from '@page/AccountingPage/AccountingRoutes';

import UserProduct from '@page/AdminPage/UserProduct';
import Products from '@page/AdminPage/Products';
import AdminUserProduct from '@page/AdminPage/AdminUserProduct';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: '/account/dashboard', element: <DashboardApp /> },
        { path: '/account/shopping/cart', element: <ShoppingCart /> },
        { path: '/account/shippings', element: <OrderShipping /> },
        ...customerRoutes,
        ...adminRouters,
        ...accountingRouters,
        ...materialRoutes,
        ...ChartsRoute
      ]
    },
    ...authRoutes,
    { path: '/', element: <Navigate to="customer/dashboard" /> },
    { path: '*', element: <NotFound /> }
  ]);
}
