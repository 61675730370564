import React, { useState } from 'react';
import { Button, Pagination } from 'antd';

export const PaginationCustom = ({ onChange, current, setCurrent, total, pageSize }) => {
  return (
    <Pagination
      pageSize={pageSize}
      total={total}
      itemRender={(page, type, originalElement) => {
        const style = {
          backgroundColor: '#E8EBEF',
          borderColor: '#E8EBEF',
          color: 'black'
        };

        if (type === 'prev') {
          return (
            <Button style={{ ...style, opacity: current === 1 ? 0.5 : 1 }} disabled={current === 1}>
              Previous
            </Button>
          );
        } else if (type === 'next') {
          return (
            <Button
              style={{ ...style, opacity: current === Math.ceil(total / pageSize) ? 0.5 : 1 }}
              disabled={current === Math.ceil(total / pageSize)}>
              Next
            </Button>
          );
        } else if (type === 'page') {
          return (
            <Button
              style={{
                backgroundColor: current === originalElement.props.children ? '#00904A' : '#E8EBEF',
                color: current === originalElement.props.children ? 'white' : 'black',
                borderColor: current === originalElement.props.children ? '#00904A' : '#E8EBEF'
              }}>
              {page}
            </Button>
          );
        }
        return originalElement;
      }}
      onChange={onChange}
      current={current}
      showSizeChanger={false}
    />
  );
};
