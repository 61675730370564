import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { v4 as uuidv4 } from 'uuid';
import { Table, Checkbox, Tooltip, Typography, Button, Space, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined, FilterOutlined } from '@ant-design/icons';
import { PaginateContext } from '@contexts/Paginate/PaginateContext';
import HandleLoading from '@components/HandleLoading';
import EnhancedTableHead from './EnhancedTableHead';
import find from 'lodash/find';

const EnhancedTableToolbar = ({ numSelected, tableName, btnDelete, filterTable }) => {
  return (
    <div
      style={{
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      {numSelected > 0 ? (
        <Typography style={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1">
          {numSelected} được chọn
        </Typography>
      ) : (
        <Typography style={{ flex: '1 1 100%' }} variant="h6" id="tableTitle">
          {tableName}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Xóa tất cả">{btnDelete}</Tooltip>
      ) : (
        <Tooltip title="Tìm kiếm">{filterTable}</Tooltip>
      )}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const findIdValue = (row) => {
  return find(row.allCells, (item) => item.column.id === 'id')?.value;
};

const getAllIdValue = (rows) => {
  if (!rows || rows.length === 0) return [];
  return rows.map((row) => findIdValue(row));
};

export default ({
  columns,
  data,
  total,
  loading,
  error,
  empty,
  isSuccess,
  tableName = 'Dữ liệu',
  btnDelete,
  filterTable,
  enableChecked = false
}) => {
  const { page, handleChangePage, pageSize, handleChangeRowsPerPage, handleSelectedIds } =
    useContext(PaginateContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 100,
      maxWidth: 400,
      textAlign: 'center'
    }),
    []
  );
  const initialState = { hiddenColumns: ['id'] };
  const dataMemo = useMemo(() => {
    return data ? data : [];
  }, [data]);

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: dataMemo,
      defaultColumn,
      initialState
    },
    useSortBy
  );

  const hasData = !loading && !error;

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = getAllIdValue(rows);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  if (empty && isSuccess && !rows?.length) {
    return empty;
  }

  useEffect(() => {
    handleSelectedIds(selected);
  }, [selected]);

  const columnsWithCheckbox = enableChecked
    ? [
        {
          title: (
            <Checkbox
              indeterminate={selected.length > 0 && selected.length < rows.length}
              checked={rows.length > 0 && selected.length === rows.length}
              onChange={(e) => handleSelectAllClick(e.target.checked)}
            />
          ),
          dataIndex: 'selection',
          render: (_, record) => (
            <Checkbox checked={isSelected(record.id)} onChange={() => handleClick(record.id)} />
          )
        },
        ...columns
      ]
    : columns;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', marginBottom: 16, marginTop: 16, boxShadow: 'none' }}>
        {enableChecked && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            tableName={tableName}
            btnDelete={btnDelete}
            filterTable={filterTable}
          />
        )}
        <Table
          {...getTableProps()}
          dataSource={rows.map((row) => {
            prepareRow(row);
            return { ...row.original, key: uuidv4() };
          })}
          columns={columnsWithCheckbox}
          loading={loading}
          pagination={{
            total: total || 0,
            pageSize: pageSize,
            current: page,
            onChange: handleChangePage,
            onShowSizeChange: handleChangeRowsPerPage
          }}
          locale={{
            emptyText: <HandleLoading loading={loading} error={error} />
          }}
          onRow={(row) => ({
            onClick: () => handleClick(row.id),
            style: { cursor: 'pointer' }
          })}
          rowClassName={(row) => (isSelected(row.id) ? 'ant-table-row-selected' : '')}
        />
      </div>
    </div>
  );
};
