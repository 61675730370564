import React from 'react';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { addCommas } from '@utils/urls';
import { Box, Icon, Card, styled, CircularProgress } from '@mui/material';

const CardRoot = styled(Card)(({ theme }) => ({
  marginBottom: '24px',
  padding: '24px !important',
  [theme.breakpoints.down('sm')]: { paddingLeft: '16px !important' }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.grey[300],
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const H3 = styled('h3')(({ theme }) => ({
  margin: 0,
  color: theme.palette.success.main,
  fontWeight: '500'
}));

const H5 = styled('h4')(({ textcolor }) => ({
  margin: 0,
  color: textcolor,
  fontWeight: '500'
}));

const Wallet = () => {
  const { data: walletInfo, isLoading } = useQuery(['get-wallet-default'], () =>
    rootApi.get('/wallets/default')
  );
  return (
    <CardRoot>
      <StyledCard elevation={0}>
        <ContentBox>
          <Icon className="icon">account_balance_wallet</Icon>
          <Box ml="12px">
            <H3>Ví GreenShip</H3>
            <H5>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                addCommas(walletInfo?.data.data.amount) || 0
              )}
              đ
            </H5>
            <H5>Tạm giữ: 0 đ</H5>
          </Box>
        </ContentBox>
      </StyledCard>
    </CardRoot>
  );
};

export default Wallet;
