import React from 'react';
import ImageGallery from 'react-image-gallery';

export const ProductPreview = ({ data }) => {
  return (
    <>
      <div className="swiper" style={{ maxWidth: '714px', width: '336px' }}>
        <ImageGallery
          lazyload={true}
          showFullscreenButton={false}
          showPlayButton={false}
          items={data ? data : []}
        />
      </div>
    </>
  );
};
