import React from 'react';
import SearchInputBox from '@components/SearchInputBox';

const FilterTable = ({ data, onChange }) => {
  const onChagneSearchText = (text) => {
    onChange({
      ...data,
      textSearch: text
    });
  };
  return <SearchInputBox onChange={onChagneSearchText} />;
};

export default FilterTable;
