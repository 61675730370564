import React, { useEffect } from 'react';

import { Icon, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Span } from '@components/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const FormField = ({
  children,
  formik,
  isLoading,
  isSuccess,
  actionName = 'Lưu',
  icon = 'send',
  isFullWidth = false,
  isDisplayBtnCancel = true
}) => {
  useEffect(() => {
    if (isSuccess) {
      formik.resetForm();
    }
  }, [isSuccess]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>{children}</Grid>
      <Box py={2} sx={{ display: 'float', marginTop: '15px' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={isFullWidth}
          sx={{ m2: 2 }}
          disabled={isLoading}>
          {isLoading && <CircularProgress size={10} />}
          <Icon>{icon}</Icon>
          <Span sx={{ pl: 1, textTransform: 'capitalize' }}>{actionName}</Span>
        </Button>
        {isDisplayBtnCancel ? (
          <Button variant="contained" color="inherit" onclick={(e) => formik.resetForm()}>
            <Icon>cancel</Icon>
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Hủy</Span>
          </Button>
        ) : (
          <span />
        )}
      </Box>
    </form>
  );
};

export default FormField;
