import React, { useState } from 'react';
import { Button, Card, Space, Typography } from 'antd';
import { HeartOutlined, ClockCircleOutlined, HeartFilled } from '@ant-design/icons';

const { Title, Text } = Typography;

export const Items = ({ imgUrl, title, targetUrl, time, priceYen, priceDong }) => {
  const [favorite, setFavorite] = useState(false);
  return (
    <Card
      cover={<img alt={title} src={imgUrl} style={{ height: '164px' }} />}
      style={{ paddingLeft: '0px', paddingRight: '0px' }}>
      <Space size="small" align="start" style={{ marginLeft: '0px', marginTop: '0px' }}>
        <ClockCircleOutlined style={{ fontSize: '16px', color: '#626D7C' }} />
        <Text style={{ fontSize: '14px' }}>{time}</Text>
      </Space>
      <Title
        style={{
          fontSize: '14px',
          fontWeight: '500'
        }}>
        {title}
      </Title>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Title style={{ fontSize: '16px', fontWeight: '600', color: '#F82859' }}>
            {priceYen}
          </Title>
          <Title style={{ fontSize: '14px', fontWeight: '400', color: '#626D7C' }}>
            {priceDong}
          </Title>
        </div>
        <Button
          type="text"
          icon={
            favorite ? (
              <HeartFilled style={{ color: '#626D7C' }} />
            ) : (
              <HeartOutlined style={{ color: '#626D7C' }} />
            )
          }
          key="favorite"
          style={{ borderRadius: '36px' }}
          onClick={() => setFavorite(!favorite)}
        />
      </Space>
    </Card>
  );
};
