import React from 'react';
import { compose } from 'redux';
import { Avatar, Divider, Grid, Button, Box } from '@mui/material';
// Components
import { Breadcrumb } from '@components/index';
import DataTable from '@components/DataTable';
import { usePagination, withPaginate } from '@contexts/Paginate/PaginateContext';
import CreateButton from './components/ButtonCreate';
import ButtonDeleteMulti from './components/ButtonDeleteMulti';
import { getListShoppingCart } from './helpers/requests';
import ActionsMenu from './components/ActionsMenu';
import ActionsCommon from '@components/ActionsCommon';
import FilterTable from './components/FilterTable';
import SimpleCard from '@components/SimpleCard';
import Typography from '@mui/material/Typography';
import { CheckBox } from '@mui/icons-material';
import ContainerBody from '@components/ContainerBody';

const getColumns = ({ refetch }) => {
  return [
    { accessor: 'id', Header: 'ID' },
    {
      Header: 'Hình ảnh',
      accessor: (cart) => {
        return <Avatar src={cart.pro_img} />;
      }
    },
    {
      accessor: 'pro_info',
      Header: 'Thông tin sản phẩm'
    },
    {
      accessor: 'price',
      Header: 'Giá'
    },
    {
      accessor: 'quantity',
      Header: 'Số lượng'
    },
    {
      accessor: 'total_money',
      Header: 'Thành tiền'
    },
    {
      Header: 'Hành động',
      accessor: (cart) => {
        return <ActionsMenu refetch={refetch} cart={cart} />;
      }
    }
  ];
};

const ShoppingCart = () => {
  const { page, pageSize } = usePagination();
  const { data, isLoading, refetch, error, isSuccess } = getListShoppingCart(page, pageSize);

  const buttonsCommon = [<CreateButton key="1" refetch={refetch} />];

  const confirmOrder = () => {
    return;
  };

  return (
    <ContainerBody>
      <div className="breadcrumb">
        <Breadcrumb
          homePath={'/account/dashboard'}
          routeSegments={[
            { name: 'Mua hàng & đấu giá', path: '/account/shopping/cart' },
            { name: 'Giỏ hàng mua hộ' }
          ]}
        />
      </div>
      <Grid container spacing={3}>
        <Grid item lg={9} md={9} sm={7} xs={12}>
          <ActionsCommon buttons={buttonsCommon} />
          <div className="list-data-table" style={{ marginTop: 15 }}>
            <DataTable
              columns={getColumns({ refetch })}
              data={data?.data}
              total={data?.total}
              loading={isLoading}
              error={error}
              isSuccess={isSuccess}
              tableName="Giỏ hàng"
              btnDelete={<ButtonDeleteMulti key="2" refetch={refetch} />}
              filterTable={<FilterTable />}
            />
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={5} xs={12}>
          <SimpleCard title="Hóa đơn mua hàng">
            <Grid container spacing={2} style={{ marginTop: 1, marginBottom: 1 }}>
              <Grid item xs={7}>
                <Typography variant="subtitle2" align="left">
                  Tổng tiền sản phẩm
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="subtitle1" align="right">
                  2,570,400 đ
                </Typography>
                <Typography variant="body2" align="right">
                  13,600 ¥
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" align="left">
                  Phí dịch vụ
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body2" align="right">
                  12,600 đ
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" align="left">
                  Phí thanh toán
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body2" align="right">
                  0 đ
                </Typography>
              </Grid>
            </Grid>
            <Divider light style={{ marginTop: 20 }} />
            <Grid container spacing={2} style={{ marginTop: 1 }}>
              <Grid item xs={7}>
                <Typography variant="subtitle2" align="left">
                  Tổng tiền hàng
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="subtitle1" align="right">
                  2,570,400 đ
                </Typography>
              </Grid>
            </Grid>
            <Divider light style={{ marginTop: 50, marginBottom: 15 }} />
            <Box style={{ textAlign: 'center', marginBottom: 10 }}>
              <Button
                variant="contained"
                color="warning"
                startIcon={<CheckBox />}
                onClick={confirmOrder}>
                Xác nhận đơn hàng
              </Button>
            </Box>
            <Typography variant="caption">
              <i>* Quý khách nên thanh toán ngay tránh sản phẩm bị tăng giá do chênh lệch tỉ giá</i>
            </Typography>
          </SimpleCard>
        </Grid>
      </Grid>
    </ContainerBody>
  );
};

export default compose(withPaginate)(ShoppingCart);
