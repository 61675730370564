import React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import formatDayTime from '@contants/formatDayTime';
import { formatPrice } from '@contants/formatPrice';
import { loadFromLocalStorage } from '@databases/localStorage';
import { constantExchangeRate } from '@contants/index';

export const ProductOption = ({ data }) => {
  const exchangeRateJPY = JSON.parse(
    loadFromLocalStorage(constantExchangeRate.EXCHANGE_RATE)
  )?.bank_sell;
  return (
    <div className="product-options">
      <Row span={24}>
        <Col className="product-option" span={4} xs={24} md={4}>
          <div className="product-option-container">
            <div className="row">
              <div className="bold">Giá khởi điểm</div>
              <div className="value">
                {formatPrice(data?.detail.start_price)} ¥ (
                <span>
                  {data?.detail.start_price > 0 ? (
                    <span>{formatPrice(data?.detail.start_price * exchangeRateJPY)} đ</span>
                  ) : (
                    <span>0 đ</span>
                  )}
                </span>
                )
              </div>
            </div>
            <div className="row">
              <div className="bold">Kết thúc sớm</div>
              <div className="value">{data?.detail.finish_soon ? 'Có' : 'Không'}</div>
            </div>
            {/* Add more rows as needed */}
          </div>
        </Col>
        <Col span={4} xs={24} md={4} className="product-option">
          <div className="product-option-container">
            <div className="row">
              <div className="bold">Số lượng</div>
              <div className="value">{formatPrice(data?.detail.quantity)} ¥</div>
            </div>
            <div className="row">
              <div className="bold">Thêm thời gian đấu</div>
              <div className="value">{data?.detail.auto_renewal ? 'Có' : 'Không'}</div>
            </div>
            {/* Add more rows as needed */}
          </div>
        </Col>
        <Col span={4} xs={24} md={4} className="product-option">
          <div className="product-option-container">
            <div className="row">
              <div className="bold">Tình trạng hàng</div>
              <div className="value">{data?.detail.status}</div>
            </div>
            <div className="row">
              <div className="bold">Mã đấu giá</div>
              <div className="value">{data?.detail.auction_id}</div>
            </div>
            {/* Add more rows as needed */}
          </div>
        </Col>
        <Col span={4} xs={24} md={4} className="product-option">
          <div className="product-option-container">
            <div className="row">
              <div className="bold">Hoàn trả</div>
              <div className="value">{data?.detail.returned ? 'Có' : 'Không'}</div>
            </div>
            <div className="row">
              <div className="bold">Hạn chế xếp hạng</div>
              <div className="value">
                {data?.detail.limit_contractor_evaluation ? 'Có' : 'Không'}
              </div>
            </div>
            {/* Add more rows as needed */}
          </div>
        </Col>
        <Col span={4} xs={24} md={4} className="product-option">
          <div className="product-option-container">
            <div className="row">
              <div className="bold">Thời gian bắt đầu</div>
              <div>
                {' '}
                {data?.detail.start_date
                  ? moment(data?.detail.start_date).format(formatDayTime.FORMAT_TIME)
                  : ''}{' '}
                (giờ VN)
              </div>
            </div>
            <div className="row">
              <div className="bold">Xác thực nhà thầu</div>
              <div className="value">{data?.detail.limit_contractor_auth ? 'Có' : 'Không'}</div>
            </div>
            {/* Add more rows as needed */}
          </div>
        </Col>
        <Col span={4} xs={24} md={4} className="product-option">
          <div className="product-option-container">
            <div className="row">
              <div className="bold">Thời gian kết thúc</div>
              <div>
                {data?.detail.end_date
                  ? moment(data?.detail.end_date).format(formatDayTime.FORMAT_TIME)
                  : ''}{' '}
                (giờ VN)
              </div>
            </div>
            <div className="row">
              <div className="bold">Trả giá cao nhất</div>
              <div>N/A</div>
            </div>
            {/* Add more rows as needed */}
          </div>
        </Col>
      </Row>
    </div>
  );
};
