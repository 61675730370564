import React, { useState } from 'react';
import { Icon, IconButton } from '@mui/material';

const ActionMenu = ({ order }) => {
  return (
    <>
      <IconButton className="button" aria-label="Xóa">
        <Icon>delete</Icon>
      </IconButton>
    </>
  );
};

export default ActionMenu;
