import wrapperApi from '@utils/baseAPI/WrapperApi';

export const getDetailProduct = (params) => {
  return wrapperApi({
    method: 'get',
    url: '/crawls',
    params
  });
};

export const bidProduct = (data) => {
  return wrapperApi({
    method: 'post',
    url: '/user-products/bidding',
    data
  });
};

export const getPriceStepByAuctionId = (id) => {
  return wrapperApi({
    method: 'get',
    url: `/user-product/next-price/${id}`
  });
};
