import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Breadcrumb } from '@components/index';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import ContainerBody from '@components/ContainerBody';
import moment from 'moment/moment';
import { Table, Tag } from 'antd';
import { SUCCESS } from '@utils/baseAPI/HttpStatusCode';
import { getUserProduct } from '@page/AdminPage/AdminUserProduct/helpers/request';
import { useNavigate } from 'react-router-dom';
import { Filters } from '@page/AdminPage/AdminUserProduct/components/Filters';
import './index.css';
import { contantAuthentication } from '@contants/index';

const AdminUserProduct = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: 0,
    limit: 10
  });

  const roles = localStorage.getItem(contantAuthentication.USER_DATA)
    ? JSON.parse(localStorage.getItem(contantAuthentication.USER_DATA)).roles
    : null;

  const fetchAdminUserProduct = () => {
    setIsLoading(true);
    getUserProduct(
      roles.indexOf('ADMIN') !== -1 || roles.indexOf('ACCOUNTING') !== -1
        ? '/admin/user-product'
        : '/user-product',
      params
    ).then((res) => {
      setIsLoading(false);
      if (res.status === SUCCESS) {
        const newData = res.data.data.map((item) => {
          return {
            ...item,
            isShowMore: false
          };
        });
        setDataTable(newData);
        setTotal(res.data.page.total);
      }
    });
  };

  useEffect(() => {
    fetchAdminUserProduct();
  }, [params]);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 50,
      render: (value, record, index) => {
        return <span>{Number(index) * (params.page - 1) + Number(index) + 1}</span>;
      }
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      width: 100
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      width: 200,
      render: (value, record) => {
        return (
          <a
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(`/account/auction?dataSearch=${record.site}/${record.site_product_id}`)
            }>
            {value}
          </a>
        );
      }
    },
    {
      title: 'Trang web',
      dataIndex: 'site',
      width: 100
    },
    {
      title: 'ID Sản phẩm trang web',
      dataIndex: 'site_product_id',
      width: 100
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (value) => {
        return (
          <div>
            {value === 'WON' && <Tag color="green">Thắng</Tag>}
            {value === 'LOST' && <Tag color="red">Thua</Tag>}
            {value === 'BIDDING' && <Tag color="orange">Đang đấu giá</Tag>}
          </div>
        );
      }
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_timestamp',
      width: 200,
      render: (value) => {
        return <div>{moment(value).format('DD/MM/YYYY HH:mm:ss')}</div>;
      }
    }
  ];

  const columnsForAdmin = [
    {
      title: 'STT',
      dataIndex: 'key',
      width: 50,
      render: (value, record, index) => {
        return <span>{Number(index) * (params.page - 1) + Number(index) + 1}</span>;
      }
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      width: 100
    },
    {
      title: 'Tên tài khoản đấu giá',
      dataIndex: 'auction_account_name',
      width: 130
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      width: 200,
      render: (value, record) => {
        return (
          <a
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(`/account/auction?dataSearch=${record.site}/${record.site_product_id}`)
            }>
            {value}
          </a>
        );
      }
    },
    {
      title: 'Trang web',
      dataIndex: 'site',
      width: 100
    },
    {
      title: 'ID Sản phẩm trang web',
      dataIndex: 'site_product_id',
      width: 100
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (value) => {
        return (
          <div>
            {value === 'WON' && <Tag color="green">Thắng</Tag>}
            {value === 'LOST' && <Tag color="red">Thua</Tag>}
            {value === 'BIDDING' && <Tag color="orange">Đang đấu giá</Tag>}
          </div>
        );
      }
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_timestamp',
      width: 200,
      render: (value) => {
        return <div>{moment(value).format('DD/MM/YYYY HH:mm:ss')}</div>;
      }
    }
  ];

  return (
    <ContainerBody>
      <div className="breadcrumb">
        <Breadcrumb
          homePath={'/customer/dashboard'}
          routeSegments={[
            { name: 'Mua hàng & đấu giá', path: '/account/bids' },
            { name: 'Sản phẩm đấu giá' }
          ]}
        />
      </div>
      <div>
        <Filters
          onSearch={(values) => setParams({ ...params, ...values })}
          onClear={() => setParams({ page: 0, limit: 10 })}
        />
        <Table
          bordered
          loading={isLoading}
          dataSource={dataTable}
          columns={
            roles.indexOf('ADMIN') !== -1 || roles.indexOf('ACCOUNTING') !== -1
              ? columnsForAdmin
              : columns
          }
          pagination={{
            total: total,
            current: params.page + 1,
            pageSize: params.limit,
            onChange: (page, limit) =>
              setParams({
                ...params,
                page: page - 1,
                limit
              }),
            showTotal: (total, range) => `${range[0]}-${range[1]} của ${total} bản ghi`
          }}
        />
      </div>
    </ContainerBody>
  );
};

export default compose(withPaginate)(AdminUserProduct);
