import React from 'react';
import { Box, styled } from '@mui/system';
import BildAuctionSuccess from '@assets/images/customer/Bild/FrameBildSuccess.png';
import BildAuctionFail from '@assets/images/customer/Bild/FrameBildFail.png';
// import ItemRow from '@components/ActionsCommon/ItemRows';
import { formatCurrency } from '@utils/utils';
import * as moment from 'moment';
import { constantExchangeRate } from '@contants/index';
import { loadFromLocalStorage } from '@databases/localStorage';
import { formatPrice } from '@contants/formatPrice';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 633,
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#fafafa'
}));

const IMG = styled('img')(() => ({
  width: '487.397px',
  height: '400px',
  marginBottom: '32px',
  backgroundColor: '#fafafa',
  flexShrink: 0
}));

const NotFoundRoot = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh !important',
  backgroundColor: '#fafafa'
}));

const ItemRow = ({ title, value, value2 }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '8px'
    }}>
    <span
      style={{
        fontWeight: 500,
        fontSize: '14px',
        fontStyle: 'normal',
        marginRight: '16px',
        color: 'var(--Text-colorcolor-Text-secondary, #626D7C);'
      }}>
      {title}
    </span>
    <span
      style={{
        fontWeight: 400,
        marginRight: '16px',
        overflow: 'hidden',
        fontSize: '16px',
        fontStyle: 'normal',
        color: 'var(--Text-colorcolor-Text-primaty, #192434);'
      }}>
      {value}{' '}
      {value2 && (
        <span
          style={{
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '14px',
            color: 'var(--Text-colorcolor-Text-primaty, #192434)',
            marginLeft: '8px'
          }}>
          {value2}
        </span>
      )}
    </span>
  </div>
);

const AuctionPageBild = ({ data, roleSale }) => {
  const { price, images, title } = data;
  const navigate = useNavigate();
  const exchangeRateJPY = JSON.parse(
    loadFromLocalStorage(constantExchangeRate.EXCHANGE_RATE)
  )?.bank_sell;
  const isBidWin = price?.is_bid_win;
  let message = roleSale
    ? 'Vui lòng nhập link sản phẩm để tìm kiếm '
    : isBidWin
    ? 'Chúc mừng bạn đã đấu thắng. Hãy liên sales để thanh toán đơn hàng'
    : 'Rất tiếc bạn đã không đấu giá thành công sản phẩm này.';
  let subject =
    roleSale && isBidWin
      ? 'Bạn đã đấu thắng'
      : !roleSale && isBidWin
      ? 'Đấu giá thành công!'
      : !roleSale && !isBidWin
      ? 'Đấu giá thất bại!'
      : 'Sản phẩm của bạn đã bị người khác đấu';
  let imageUrl = isBidWin ? BildAuctionSuccess : BildAuctionFail;
  let imagePro = images[0]?.thumbnail;
  let win_time = price?.win_time;

  const redirectPage = () => {
    navigate(`/account/auction/${data?.detail?.auction_id}`);
  };
  return (
    <NotFoundRoot>
      <JustifyBox>
        <IMG src={imageUrl} alt="" />
        <div style={{ margin: '16px 0', textAlign: 'center' }}>
          <h2
            style={{
              color: 'var(--Text-colorcolor-Text-primaty, #192434)',
              textAlign: 'center',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '32px',
              marginBottom: '8px'
            }}>
            {subject}
          </h2>
          <span
            style={{
              color: ' var(--Text-colorcolor-Text-secondary, #626D7C)',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px' /* 142.857% */,
              textAlign: 'center'
            }}>
            {message}
          </span>
        </div>
        {!roleSale ? (
          <div
            style={{
              marginTop: '24px',
              padding: '24px',
              borderRadius: '12px',
              background: ' var(--Backgroud-Backgroud-1, #FFF)'
            }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <img src={imagePro} style={{ width: '120px', height: '120px' }} alt="" />
              <div style={{ marginLeft: '16px', textAlign: 'center' }}>
                <h2
                  style={{
                    overflow: 'hidden',
                    color: 'var(--Text-colorcolor-Text-primaty, #192434);',
                    textOverflow: 'ellipsis',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    marginTop: 0,
                    marginBottom: '0'
                  }}>
                  {title?.text}
                </h2>
                {isBidWin ? (
                  <ItemRow
                    title="Giá sản phẩm:"
                    value={`${formatCurrency(Math.round(price?.bid_price))} ¥`}
                    value2={`(≈ ${formatPrice(price?.win_price * exchangeRateJPY, 2)} đ)`}
                  />
                ) : (
                  ''
                )}
                <ItemRow title="Thời gian:" value={moment(win_time).format('DD/MM/YYYY HH:mm')} />
              </div>
            </div>
          </div>
        ) : (
          <Button
            style={{
              background: 'var(--Primary-Basic, #00904A)',
              borderRadius: '4px',
              color: 'rgb(255, 255, 255)',
              borderColor: 'var(--Primary-Basic, #00904A)',
              fontSize: '14px',
              fontStyle: 'normal',
              textAlign: 'center'
            }}
            onClick={redirectPage}>
            Chi tiết sản phẩm
          </Button>
        )}
      </JustifyBox>
    </NotFoundRoot>
  );
};

export default AuctionPageBild;
