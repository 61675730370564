import rootApi from '@utils/baseAPI/WrapperApi';

export const apiGetAllUser = (data) => {
  return rootApi.get('api/v1/drm/order', { params: data });
};
export const apiAddUserOperator = (data) => rootApi.post('/admin/users/create-user', data);
export const apiUpdateRoleUserOperator = (id, data) =>
  rootApi.post(`admin/users/change-role/${id}`, data);
export const apiDeleteUserOperator = (id) =>
  rootApi.delete(`admin/users/remove-operator-user/${id}`);
