import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Typography, Input, Button, Select, message } from 'antd';
import './index.css';
import { formatCurrency, formatVNDCurrency } from '@utils/utils';
import { Timer } from './common/Timer';
import ProductQuantity from './common/ProductQuantity';
import { bidProduct, getDetailProduct } from '../../helpers/request';
import { SUCCESS } from '@utils/baseAPI/HttpStatusCode';
import { Svgs } from '@components/IconSvg';
import { apiGetCurrencies } from '@services/ManageAuctions/AuctionServices';
import { useQuery } from 'react-query';
import { apiGetUserInfo } from '@services/ManageUser/UserServices';
import { apiActiveVIP } from '@services/MangeCustomer/CustomerServices';
import { showSuccess } from '@components/notification';
import { URI_CRAWL } from '@contants/options';
import { isEmpty } from 'lodash';
const { Title, Text } = Typography;

const AuctionPopup = ({
  visible,
  onCancel,
  type,
  remainingTime,
  data,
  handleGetDataAuction,
  customerId,
  roleSale
}) => {
  console.log('🚀 ~ customerId:', customerId);
  console.log('🚀 ~ roleSale:', roleSale);
  const { data: user } = useQuery(['get-user-info'], () => apiGetUserInfo());
  const userInfo = user?.data.data;
  const { title } = data;
  let titleName = 'Đấu giá';
  if (type === 3) {
    titleName = 'Săn phút chót';
  } else if (type === 2) {
    titleName = 'Mua thẳng';
  }
  const [price, setPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(userInfo?.is_vip);
  const [curreny, setCurrency] = useState();
  const [result, setResult] = useState(null);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const param = {
        currency_code: 'JPY'
      };
      const res = await apiGetCurrencies(param);
      if (res?.data?.data) {
        setCurrency(res?.data?.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (visible) {
      fetchData();
    }
  }, [visible]);
  const handleActive = async () => {
    try {
      setIsLoading(true);
      await apiActiveVIP();
      showSuccess({ message: 'Kích hoạt thành công!' });
      setIsActive(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const auction = async () => {
    if (type === 1) {
      setIsLoading(true);
      try {
        // Define the parameters to be sent in the bidProduct request
        const bidParams = {
          product_id: data?.id,
          buy_now: false,
          price: +price
        };

        // If roleSale is true, add customerId to the bidParams
        if (roleSale) {
          bidParams.customer_id = +customerId;
        }
        const res = await bidProduct(bidParams);
        setIsLoading(false);
        if (res.status === SUCCESS) {
          message.success('Đấu giá thành công');
          onCancel();
        } else {
          onCancel();
          message.error('Bid failed with status: ' + res.status);
        }
      } catch (error) {
        setIsLoading(false);
        onCancel();
        message.error('Error during bid process');
        console.error('Error during bid process:', error);
      }
    }
  };

  useEffect(() => {
    if (data?.price) {
      if (data?.price?.is_bid_win !== undefined) {
        setResult(data);
      }
    }
  });
  useEffect(() => {
    if (result) {
      handleGetDataAuction(result);
    }
  }, [result]);
  const calculatorNextPrice = () => {
    const start_price = parseInt(data?.detail?.start_price, 10) || 0;
    const current_price = parseInt(data?.price?.value, 10) || 0;
    if (start_price == current_price) return current_price;
    if (current_price < 1000) return current_price + 10;
    else if (1000 <= current_price && current_price < 5000) return current_price + 100;
    else if (5000 <= current_price && current_price < 10000) return current_price + 250;
    else if (10000 <= current_price && current_price < 50000) return current_price + 500;
    else return current_price + 1000;
  };

  useEffect(() => {
    const calPrice = calculatorNextPrice();
    setPrice(calPrice);
  }, []);
  return (
    <Modal
      title={titleName}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="back"
          style={{
            height: '36px',
            backgroundColor: '#E8EBEF',
            color: '#192434',
            fontSize: '14px',
            fontWeight: '500',
            fontStyle: 'normal',
            borderRadius: '4px'
          }}
          onClick={onCancel}>
          Hủy bỏ
        </Button>,
        <Button
          key="submit"
          style={{
            backgroundColor: isActive ? '#00904A' : '#D3D3D3',
            color: '#FFFF',
            height: '36px',
            fontSize: '14px',
            fontWeight: '500',
            fontStyle: 'normal',
            borderRadius: '4px'
          }}
          loading={isLoading}
          onClick={auction}
          disabled={!isActive ? true : false}>
          {titleName}
        </Button>
      ]}
      width={720}
      style={{
        paddingLeft: '16px',
        paddingRight: '16px',
        cursor: 'pointer'
      }}>
      <div>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Col span={24}>
              <Title level={5} style={{ margin: '0' }}>
                {title?.text}
              </Title>
            </Col>
            <Col span={24}>
              <Timer remainingTime={remainingTime} />
            </Col>
            <Col span={24}>
              {[1, 3].includes(type) && (
                <>
                  <Col span={24} style={{ padding: 0, marginBottom: '14px' }}>
                    <Title style={{ fontSize: '14px', fontWeight: '500', color: '#626D7C' }}>
                      Giá hiện tại
                    </Title>
                    <div>
                      <Text style={{ fontSize: '18px', fontWeight: '600', color: '#192434' }}>
                        {` ${formatCurrency(data?.price?.value, 2)} ¥`}
                      </Text>
                      <Text style={{ fontSize: '14px', fontWeight: '400', color: '#626D7C' }}>
                        {`(≈ ${formatVNDCurrency(data?.price?.value * curreny?.bank_sell, 2)} đ)`}
                      </Text>
                    </div>
                  </Col>
                  <Col span={24} style={{ padding: 0, marginTop: '16px' }}>
                    <Title style={{ fontSize: '14px', fontWeight: '500', color: '#626D7C' }}>
                      Giá đấu của bạn
                    </Title>
                  </Col>
                  <Col span={24} style={{ padding: 0 }}>
                    <Input
                      name="bid"
                      onChange={(e) => setPrice(e.target.value)}
                      addonAfter={`≈ ${formatVNDCurrency(price * curreny?.bank_sell, 2)} đ`}
                      style={{ width: '100%' }}
                      value={price}
                      styles={{ color: '#192434', margin: '8px 0' }}
                    />
                  </Col>
                  {/* <Col span={24} style={{ padding: 0 }}>
                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '8px'
                      }}>
                      <div>
                        <Title
                          style={{
                            fontSize: '12px',
                            margin: 0,
                            fontWeight: '400',
                            color: '#626D7C',
                            fontStyle: 'normal'
                          }}>
                          Giá tối thiểu
                          {` ${formatCurrency(data?.detail?.start_price)} ¥`}
                        </Title>
                      </div>
                    </Space>
                  </Col> */}
                  {type === 1 && !isActive && (
                    <Row
                      style={{
                        padding: '10px',
                        border: '1px solid #FFD700',
                        borderRadius: '5px',
                        backgroundColor: '#FFF8DC',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '16px'
                      }}>
                      <Col
                        span={14}
                        style={{ marginRight: '1px', display: 'flex', alignItems: 'center' }}>
                        <Svgs.WarningSvg
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px'
                          }}
                        />
                        <Text style={{ fontSize: '14px', fontWeight: '400', fontStyle: 'normal' }}>
                          Vui lòng kích hoạt VIP để đấu giá sản phẩm
                        </Text>
                      </Col>
                      <Col
                        span={8}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}>
                        <Button
                          type="primary"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#192434',
                            fontWeight: '500'
                          }}
                          onClick={handleActive}>
                          <Text
                            style={{
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              textAlign: 'center',
                              color: 'var(--Text-colorcolor-Text-primaty, #192434)',
                              marginRight: '8px'
                            }}>
                            Kích hoạt VIP
                          </Text>
                          <Svgs.VectorRight />
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {type === 3 && (
                <Col span={24} style={{ padding: 0 }}>
                  <Col span={24} style={{ padding: 0, marginTop: '16px' }}>
                    <Title style={{ fontSize: '14px', fontWeight: '500', color: '#626D7C' }}>
                      Thời gian đặt giá săn
                    </Title>
                  </Col>
                  <Col span={24} style={{ padding: 0 }}>
                    <Select
                      style={{
                        width: '100%'
                      }}
                      defaultValue={'1'}
                      tokenSeparators={[',']}
                      options={[
                        {
                          value: '1',
                          label: '1 phút trước khi kết thúc phiên'
                        }
                      ]}
                    />
                  </Col>
                </Col>
              )}
              {type === 2 && (
                <>
                  <Col span={24} style={{ padding: 0, marginBottom: '14px' }}>
                    <Title style={{ fontSize: '14px', fontWeight: '500', color: '#626D7C' }}>
                      Giá mua thẳng
                    </Title>
                    <div>
                      <Text style={{ fontSize: '18px', fontWeight: '600', color: '#192434' }}>
                        {` ${formatCurrency(data?.price?.buy_now, 2)} ¥`}
                      </Text>
                      <Text style={{ fontSize: '14px', fontWeight: '400', color: '#626D7C' }}>
                        {`(≈${formatCurrency(data?.price?.buy_now * curreny?.bank_sell, 2)} đ)`}
                      </Text>
                    </div>
                  </Col>
                  <Col span={24} style={{ padding: 0 }}>
                    <Col span={24} style={{ padding: 0, marginTop: '16px' }}>
                      <Title style={{ fontSize: '14px', fontWeight: '500', color: '#626D7C' }}>
                        Số lượng
                      </Title>
                    </Col>
                    <Col span={24} style={{ padding: 0 }}>
                      <ProductQuantity />
                    </Col>
                  </Col>
                </>
              )}
            </Col>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AuctionPopup;
