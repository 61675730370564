import React from 'react';
import { Modal, Row, Col, Typography, Form, Input, Button, Space, Statistic, Divider } from 'antd';
import { formatCurrency } from '@utils/utils';
import '../index.css';
const { Title, Text } = Typography;

export const Timer = ({ remainingTime }) => {
  const { days, hours, minutes, seconds } = remainingTime;
  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: '#F4F5F7',
        borderRadius: '6px',
        padding: '16px',
        margin: '24px 0'
      }}>
      <Row className="time_row" align="middle" justify="space-around" textAlign="center">
        <Col span={3}>
          <Title level={4}>
            <Text strong>{days}</Text>
          </Title>
          <Title level={5} style={{ marginBottom: 0 }}>
            <Text>Ngày</Text>
          </Title>
        </Col>
        <Col>:</Col>
        <Col span={3}>
          <Title level={4}>
            <Text strong>{hours}</Text>
          </Title>
          <Title level={5} style={{ marginBottom: 0 }}>
            <Text>Giờ</Text>
          </Title>
        </Col>
        <Col>:</Col>
        <Col span={3}>
          <Title level={4}>
            <Text strong>{minutes}</Text>
          </Title>
          <Title level={5} style={{ marginBottom: 0 }}>
            <Text>Phút</Text>
          </Title>
        </Col>
        <Col>:</Col>
        <Col span={3}>
          <Title level={4}>
            <Text strong>{seconds}</Text>
          </Title>
          <Title level={5} style={{ marginBottom: 0 }}>
            <Text>Giây</Text>
          </Title>
        </Col>
      </Row>
    </div>
  );
};
