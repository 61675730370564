import React from 'react';
import {
  Avatar,
  Box,
  Card,
  Icon,
  IconButton,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Link
} from '@mui/material';
import { Paragraph } from '@components/Typography';

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  paddingRight: '24px',
  marginBottom: '12px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' }
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)'
}));

const TopSellingTable = () => {
  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgPrimary = palette.primary.main;
  const bgSecondary = palette.secondary.main;

  return (
    <Card elevation={3} sx={{ pt: '20px', mb: 3 }}>
      <CardHeader>
        <Title>LỊCH SỬ GIAO DỊCH</Title>
        <Select size="small" defaultValue="this_month">
          <MenuItem value="this_month">Tháng này</MenuItem>
          <MenuItem value="last_month">Tháng trước</MenuItem>
        </Select>
      </CardHeader>

      <Box overflow="auto">
        <ProductTable>
          <TableHead>
            <TableRow>
              <TableCell sx={{ px: 3 }} colSpan={4}>
                Giao dịch
              </TableCell>
              <TableCell sx={{ px: 0 }} colSpan={2}>
                Ngày tạo/khớp
              </TableCell>
              <TableCell sx={{ px: 0 }} colSpan={2}>
                Số tiền
              </TableCell>
              <TableCell sx={{ px: 0 }} colSpan={2}>
                Trạng thái
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionList.map((trans, index) => (
              <TableRow key={index} hover>
                <TableCell colSpan={4} align="left" sx={{ px: 0, textTransform: 'capitalize' }}>
                  <Link href="#">{trans.id}</Link>
                </TableCell>
                <TableCell align="left" colSpan={2} sx={{ px: 0, textTransform: 'capitalize' }}>
                  {trans.date}
                </TableCell>
                <TableCell align="left" colSpan={2} sx={{ px: 0, textTransform: 'capitalize' }}>
                  {trans.money} VND
                </TableCell>
                <TableCell sx={{ px: 0 }} align="left" colSpan={2}>
                  {trans.money ? (
                    trans.money < 20 ? (
                      <Small bgcolor={bgSecondary}>Đang xử lý</Small>
                    ) : (
                      <Small bgcolor={bgPrimary}>Thành công</Small>
                    )
                  ) : (
                    <Small bgcolor={bgError}>Không thành công</Small>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ProductTable>
      </Box>
    </Card>
  );
};

const transactionList = [
  {
    id: '0xbb8541d7d4ec74d5d57be9',
    date: '2023-02-10',
    money: 15
  },
  {
    id: '0xbb8541d7d4ec74d5d57be9',
    date: '2023-02-10',
    money: 30
  },
  {
    id: '0xbb8541d7d4ec74d5d57be9',
    date: '2023-02-10',
    money: 35
  },
  {
    id: '0xbb8541d7d4ec74d5d57be9',
    date: '2023-02-10',
    money: 0
  },
  {
    id: '0xbb8541d7d4ec74d5d57be9',
    date: '2023-02-10',
    money: 5
  }
];

export default TopSellingTable;
