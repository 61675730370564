import wrapperApi from '@utils/baseAPI/WrapperApi';

export const getCategories = () => {
  return wrapperApi({
    method: 'get',
    url: '/categories'
  });
};

export const getProductsByCategory = (site, site_url, page) => {
  return wrapperApi({
    method: 'get',
    url: `/crawls/products?site=${site}&site_url=${site_url}&page=${page}`
  });
};
