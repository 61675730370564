import React, { useState } from 'react';
import { Button, Card, Divider, Flex, Space, Typography } from 'antd';
import {
  HeartOutlined,
  ClockCircleOutlined,
  HeartFilled,
  StarFilled,
  ArrowRightOutlined
} from '@ant-design/icons';
import { Avatar, Stack } from '@mui/material';
import ModalAddFavoriteSeller from '@page/CustomerPage/Common/Modal/ModalAddFavoriteSeller';
import {
  apiAddFavoriteSeller,
  apiRemoveFavoriteSeller
} from '@services/MangeCustomer/CustomerServices';
import { showSuccess } from '@components/notification';
import { useQuery } from '@utils/utils';

const { Title, Text } = Typography;

export const Seller = ({ sellerId }) => {
  const query = useQuery();
  const username = query.get('username');
  const avatar = query.get('avatar');
  const rating_ratio = query.get('rating_ratio');
  const rating_count = query.get('rating_count');
  const site = query.get('site');
  const is_favourite = query.get('is_favourite');
  const [favorite, setFavorite] = useState(is_favourite);
  const [visible, setVisible] = useState(false);
  const [remark, setRemark] = useState('');
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const handleAddFavorite = async () => {
    try {
      setLoadingFavorite(true);
      await apiAddFavoriteSeller({
        seller_id: Number(sellerId),
        remark: remark
      });
      setVisible(false);
      setFavorite(true);
      showSuccess({ message: 'Đã thêm cửa hàng vào danh sách yêu thích!' });
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingFavorite(false);
    }
  };
  const handleRemoveFavorite = async () => {
    try {
      setLoadingFavorite(true);
      await apiRemoveFavoriteSeller({
        seller_id: sellerId,
        remark: remark
      });
      showSuccess({ message: 'Đã xoá khỏi danh sách yêu thích!' });
      setFavorite(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingFavorite(false);
    }
  };
  return (
    <>
      <Card style={{ padding: '8px', paddingRight: '0px', cursor: 'pointer' }} size="small">
        <Flex style={{ marginTop: '0px' }} vertical>
          <Space style={{ display: 'flex' }}>
            <Avatar
              alt={username?.toUpperCase()}
              src={avatar ? avatar : 'https://'}
              sx={{ bgcolor: '#00904A' }}
            />
            <div style={{ marginLeft: '4px' }}>
              <Title style={{ fontSize: '14px', fontWeight: '600' }}>{username}</Title>
              <div>
                <Text style={{ fontSize: '14px', fontWeight: '400', color: '#626D7C' }}>
                  Đánh giá{' '}
                </Text>
                <Text style={{ marginRight: '2px', fontSize: '14px', fontWeight: '500' }}>
                  {rating_count}
                </Text>
                <Divider type="vertical" />
                <Text style={{ color: '#192434', marginRight: '4px' }}>{rating_ratio} tốt</Text>
              </div>
            </div>
          </Space>

          <Button
            loading={loadingFavorite}
            type="text"
            icon={
              favorite ? (
                <HeartFilled style={{ color: '#F82859' }} />
              ) : (
                <HeartOutlined style={{ color: '#F82859' }} />
              )
            }
            key="favorite"
            style={{
              borderRadius: '4px',
              backgroundColor: '#E8EBEF',
              width: '100%',
              marginTop: '16px',
              fontSize: '14px',
              fontWeight: '500'
            }}
            onClick={() => (favorite ? handleRemoveFavorite() : setVisible(true))}>
            Yêu thích
          </Button>
          <Text
            style={{ color: '#00904A', marginTop: '12px' }}
            onClick={() => window.open(site, '_blank')}>
            Chi tiết trên Yahoo! Auction <ArrowRightOutlined />
          </Text>
        </Flex>
      </Card>
      <ModalAddFavoriteSeller
        visible={visible}
        setVisible={setVisible}
        remark={remark}
        setRemark={setRemark}
        username={username}
        onConfirm={handleAddFavorite}
      />
    </>
  );
};
