import React, { useState } from 'react';
import { Modal, Input, Divider, Button, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const ModalAddFavoriteSeller = ({
  visible,
  onConfirm,
  setVisible,
  username,
  remark,
  setRemark
}) => {
  const handleOk = () => {
    onConfirm(remark);
    setRemark('');
  };

  const handleCancel = () => {
    setVisible(false);
    setRemark('');
  };

  return (
    <Modal
      title="Cập nhật người bán yêu thích"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" style={{ height: '36px' }} onClick={handleCancel}>
          Hủy
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: '#00904A', color: '#FFFF', height: '36px' }}
          onClick={onConfirm}>
          Lưu lại
        </Button>
      ]}>
      <Typography.Title style={{ fontSize: '14px', fontWeight: '400' }}>Người bán</Typography.Title>
      <Typography.Text style={{ fontSize: '14px', fontWeight: '500' }}>
        <UserOutlined /> {username}
      </Typography.Text>
      <Typography.Title style={{ fontSize: '14px', fontWeight: '400' }}>
        Ghi chú thêm
      </Typography.Title>
      <TextArea
        style={{ width: '100%' }}
        rows={4}
        value={remark}
        onChange={(e) => setRemark(e.target.value)}
        placeholder="Nhập nội dung ghi chú"
      />
    </Modal>
  );
};

export default ModalAddFavoriteSeller;
