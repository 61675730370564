import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteMultiCart } from '../../helpers/requests';
import { CircularProgress, Icon, IconButton } from '@mui/material';
import { usePagination } from '@contexts/Paginate/PaginateContext';
import DeleteIcon from '@mui/icons-material/Delete';

const ButtonDeleteMulti = ({ refetch }) => {
  const { mutateAsync, isLoading } = deleteMultiCart();
  const { selectedIds, handleSelectedIds } = usePagination();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    refetch();
    setOpen(false);
  };

  const handleDelete = () => {
    mutateAsync(selectedIds).then(() => {
      handleSelectedIds([]);
      handleClose();
    });
  };
  return (
    <>
      <IconButton>
        <DeleteIcon onClick={handleOpenDialog} color="error" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Thông báo</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ display: 'flex' }}>
            Bạn có muốn xóa sản phẩm đã chọn từ giỏ hàng không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained" color="primary" disabled={isLoading}>
            {isLoading && <CircularProgress size={10} />} Đồng ý
          </Button>
          <Button onClick={handleClose} autoFocus variant="contained" color="inherit">
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ButtonDeleteMulti.propTypes = {
  ids: PropTypes.array,
  refetch: PropTypes.func.isRequired
};

export default ButtonDeleteMulti;
