export const ASSET_TYPE = [
  { label: 'Hàng gia dụng', value: '0' },
  { label: 'Hàng quần áo thời trang', value: '1' }
];

export const GENDER = [
  { label: 'Nam', value: 'MALE' },
  { label: 'Nữ', value: 'FEMALE' },
  { label: 'Khác', value: 'OTHER' }
];

export const optionSortProduct = [
  {
    value: 'score-popular',
    label: 'Phổ biến nhất & Sản phẩm mới'
  },
  {
    value: 'cbids-a',
    label: 'Giá: Thấp đến cao'
  },
  {
    value: 'cbids-d',
    label: 'Giá: Cao đến thấp'
  },
  {
    value: 'recommend',
    label: 'Gợi ý cho bạn'
  }
];

export const optionPageSize = [
  {
    value: 10,
    label: '10'
  },
  {
    value: 20,
    label: '20'
  },
  {
    value: 25,
    label: '25'
  },
  {
    value: 50,
    label: '50'
  }
];
export const URI_CRAWL = 'https://page.auctions.yahoo.co.jp/jp/auction';
