import { useQuery, useMutation } from 'react-query';
import request from '@utils/baseAPI/WrapperApi';
import { URL_API } from '@configs';
import { getUrlPaging } from '@utils/urls';

export const createOrderShoppingCart = () => {
  const url = `${URL_API}/create-order`;
  return useMutation(['create-order'], (data) => request({ url, data, method: 'POST' }));
};

export const getListShoppingCart = (page = 1, pageSize = 9999) => {
  const url = getUrlPaging(`${URL_API}/list-shopping-cart`, page, pageSize);
  const { data, isLoading, refetch } = useQuery(['list-order', page, pageSize], () =>
    request({ url })
  );
  const resp = {
    data: [
      {
        id: 1,
        pro_img: '/assets/images/products/headphone-2.jpg',
        pro_info: 'Nước hoa Channel v191 Orient England',
        price: 2980,
        quantity: 1
      },
      {
        id: 2,
        pro_img: '/assets/images/products/headphone-3.jpg',
        pro_info: 'Nước hoa Channel v191 Orient England',
        price: 2980,
        quantity: 1
      }
    ],
    total: 2
  };
  return { data: resp, isLoading, refetch };
};

export const deleteCart = (cartId) => {
  const url = `${URL_API}/cart/${cartId}`;
  return useMutation(['delete-order'], (data) => request({ url, data, method: 'DELETE' }));
};

export const deleteMultiCart = () => {
  const url = `${URL_API}/cart`;
  return useMutation(['delete-multi-cart'], (data) => request({ url, data, method: 'DELETE' }));
};
