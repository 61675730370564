import React, { useEffect, useState } from 'react';
import { SUCCESS } from '@utils/baseAPI/HttpStatusCode';
import { getCategories, getProductsByCategory } from '@page/AdminPage/Products/helpers/request';
import { Tree } from 'antd';

function getItem(items) {
  return items.map((item) => {
    return {
      title: item.name,
      key: String(item.id),
      site: item.site,
      site_url: item.site_url,
      children: item.children ? getItem(item.children) : []
    };
  });
}

export const Categories = ({ setProducts, setLoading, setMaxPage, page }) => {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [siteUrl, setSiteUrl] = useState(null);

  const fetchProductInCategory = (site, site_url) => {
    setLoading(true);
    getProductsByCategory(site, site_url, page).then((res) => {
      setLoading(false);
      if (res.status === SUCCESS) {
        setProducts(res.data.data);
        setMaxPage(res.data.page.max_next_page);
      }
    });
  };

  useEffect(() => {
    if (!siteSelected) return;
    if (!siteUrl) return;
    fetchProductInCategory(siteSelected, siteUrl);
  }, [siteSelected, siteUrl, page]);

  const fetchCategory = () => {
    getCategories().then((res) => {
      if (res.status === SUCCESS) {
        const processedItems = getItem(res.data.data.children);
        console.log(processedItems);
        setItems(processedItems);
        setCategories(res.data.data.children);
        const keys = [String(res.data.data.children[0].id)];
        setSelectedKey(keys);
        setSiteSelected(res.data.data.children[0].site);
        setSiteUrl(res.data.data.children[0].site_url);
      }
    });
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const treeData = [
    {
      title: 'parent 1',
      key: '0-0',
      children: [
        {
          title: 'parent 1-0',
          key: '0-0-0',
          disabled: true,
          children: [
            {
              title: 'leaf',
              key: '0-0-0-0',
              disableCheckbox: true
            },
            {
              title: 'leaf',
              key: '0-0-0-1'
            }
          ]
        },
        {
          title: 'parent 1-1',
          key: '0-0-1',
          children: [
            {
              title: <span style={{ color: '#1677ff' }}>sss</span>,
              key: '0-0-1-0'
            }
          ]
        }
      ]
    }
  ];

  return (
    <div className="side-nav">
      <h4 className="modtitle bold">
        <span>Danh mục sản phẩm</span>
      </h4>
      <div className="side_body list-category">
        <Tree
          onSelect={(e, info) => {
            fetchProductInCategory(info.node.site, info.node.site_url);
          }}
          treeData={items}
        />
      </div>
    </div>
  );
};
