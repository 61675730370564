import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import '../index.css';

const ProductQuantity = () => {
  const [quantity, setQuantity] = useState(1);
  const stock = 6; // Example stock value

  const increment = () => {
    if (quantity < stock) {
      setQuantity(quantity + 1);
    }
  };

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const onChange = (e) => {
    const value = Math.max(1, Math.min(stock, Number(e.target.value)));
    setQuantity(value);
  };

  return (
    <div className="product-quantity">
      <div className="input-group">
        <Button className="input-group_pre" onClick={decrement} disabled={quantity <= 1}>
          -
        </Button>
        <input type="number" value={quantity} onChange={onChange} className="input-number" />
        <Button className="input-group_next" onClick={increment} disabled={quantity >= stock}>
          +
        </Button>
      </div>
      <Typography.Text className="stock">Có sẵn {stock} sản phẩm</Typography.Text>
    </div>
  );
};

export default ProductQuantity;
