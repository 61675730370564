import React, { useState } from 'react';
import './index.css';
import { Button } from 'antd';
import { ReactComponent as Down } from '@assets/images/customer/down.svg';
import { ReactComponent as Up } from '@assets/images/customer/up.svg';

export const ProductDescription = ({ data }) => {
  const maxLength = 500;
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  if (data?.description.length <= maxLength) {
    return <p>{data?.description}</p>;
  }
  return (
    <div className="ProductExplanation">
      <div className="ProductExplanation__header">
        <div className="ProductExplanation__title">Mô tả sản phẩm</div>
      </div>
      <div className="ProductExplanation__body highlightWordSearch">
        <div
          className="ProductExplanation__commentArea"
          dangerouslySetInnerHTML={{
            __html: isExpanded ? data.description : `${data.description.substring(0, maxLength)}...`
          }}
        />
      </div>
      {data.description.length > maxLength && (
        <div className="ProductExplanation_bot" onClick={toggleReadMore}>
          <div className="ProductExplanation_bot_btn">
            {isExpanded ? 'Thu gọn' : 'Xem thêm'}{' '}
            {isExpanded ? (
              <Up className="ProductExplanation_bot_icon" />
            ) : (
              <Down className="ProductExplanation_bot_icon" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
