import wrapperApi from '@utils/baseAPI/WrapperApi';

export const getUserProduct = (url, params) => {
  return wrapperApi({
    method: 'get',
    url,
    params
  });
};

export const exportAdminList = (data) => {
  return wrapperApi({
    method: 'post',
    responseType: 'blob',
    url: '/admin/user-product/export',
    data
  });
};
